import React, { useEffect } from "react";

export default function LanguageChanger() {
  useEffect(() => {
    const initializeGoogleTranslate = () => {
      if (window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,hi,mr",
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          "google_translate_element"
        );

        // Add a mutation observer to reset language if English is selected
        const observer = new MutationObserver(() => {
          const selectedLanguage =
            document.querySelector(".goog-te-combo").value;

          if (selectedLanguage === "en") {
            const iframe = document.querySelector(
              "iframe.goog-te-banner-frame"
            );
            if (iframe) {
              iframe.contentWindow.location.reload();
            }
          }
        });

        const comboElement = document.querySelector(".goog-te-combo");
        if (comboElement) {
          observer.observe(comboElement, { attributes: true, childList: true });
        }
      }
    };

    // Define the global callback function before loading the script
    window.googleTranslateElementInit = initializeGoogleTranslate;

    // Load the Google Translate script
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    script.onerror = () => {
      console.error("Failed to load Google Translate script.");
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script and widget
      const existingScript = document.querySelector(
        'script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }

      const translateElement = document.getElementById(
        "google_translate_element"
      );
      if (translateElement) translateElement.innerHTML = "";
    };
  }, []); // Run only once when the component mounts

  return (
    <div className="rounded border p-1 bg-gray-100 capitalize flex items-center justify-center">
      <div id="google_translate_element"></div>
    </div>
  );
}
