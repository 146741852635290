// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar::-webkit-scrollbar {
   width: 5px;
}

.sidebar::-webkit-scrollbar-track {
   background-color: #1f2937;
}

.sidebar::-webkit-scrollbar-thumb {
   background-color: #4b5563;
}

.sidebar {
   width: 22%;
   height: 100vh;
   overflow-x: auto;
   transition: 0.1s all ease-in-out;
}

.sidebarClosed {
   height: 100vh;
   overflow-x: auto;
   width: 8%;
   transition: 0.4s all ease-in-out;
}

.sidebarClosed > :first-child {
   flex-direction: column-reverse;
   align-items: center;
   justify-content: center;
}
.sidebarClosed > :nth-child(2) {
   align-items: center;
   justify-content: center;
}

/* -----------Media query ------------- */
@media (max-width: 1300px) {
   .sidebar {
      width: 28%;
   }
   .sidebarClosed {
      width: 10%;
   }
}

@media (max-width: 1024px) {
   .sidebar {
      width: 290px;
      position: fixed;
      z-index: 10;
   }
   .sidebarClosed {
      width: 14%;
   }
}
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;GACG,UAAU;AACb;;AAEA;GACG,yBAAyB;AAC5B;;AAEA;GACG,yBAAyB;AAC5B;;AAEA;GACG,UAAU;GACV,aAAa;GACb,gBAAgB;GAChB,gCAAgC;AACnC;;AAEA;GACG,aAAa;GACb,gBAAgB;GAChB,SAAS;GACT,gCAAgC;AACnC;;AAEA;GACG,8BAA8B;GAC9B,mBAAmB;GACnB,uBAAuB;AAC1B;AACA;GACG,mBAAmB;GACnB,uBAAuB;AAC1B;;AAEA,yCAAyC;AACzC;GACG;MACG,UAAU;GACb;GACA;MACG,UAAU;GACb;AACH;;AAEA;GACG;MACG,YAAY;MACZ,eAAe;MACf,WAAW;GACd;GACA;MAMG,UAAU;GAJb;AACH","sourcesContent":[".sidebar::-webkit-scrollbar {\n   width: 5px;\n}\n\n.sidebar::-webkit-scrollbar-track {\n   background-color: #1f2937;\n}\n\n.sidebar::-webkit-scrollbar-thumb {\n   background-color: #4b5563;\n}\n\n.sidebar {\n   width: 22%;\n   height: 100vh;\n   overflow-x: auto;\n   transition: 0.1s all ease-in-out;\n}\n\n.sidebarClosed {\n   height: 100vh;\n   overflow-x: auto;\n   width: 8%;\n   transition: 0.4s all ease-in-out;\n}\n\n.sidebarClosed > :first-child {\n   flex-direction: column-reverse;\n   align-items: center;\n   justify-content: center;\n}\n.sidebarClosed > :nth-child(2) {\n   align-items: center;\n   justify-content: center;\n}\n\n/* -----------Media query ------------- */\n@media (max-width: 1300px) {\n   .sidebar {\n      width: 28%;\n   }\n   .sidebarClosed {\n      width: 10%;\n   }\n}\n\n@media (max-width: 1024px) {\n   .sidebar {\n      width: 290px;\n      position: fixed;\n      z-index: 10;\n   }\n   .sidebarClosed {\n      width: 12%;\n   }\n}\n@media (max-width: 1024px) {\n   .sidebarClosed {\n      width: 14%;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
