import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyCoupon, applyCouponCode, clearCoupon } from "../../store/cartSlice";
import { useCurrencyConverter } from "../../utils/useCurrencyConverter";
import { useSnackbar } from "notistack";
import publicCommunication from "../../service/publicCommunication";

const PriceSidebar = ({ cartItems, isCouponApply = false }) => {
   const dispatch = useDispatch();
   const { discount, couponCode, finalAmount } = useSelector((state) => state.cart);
   const { convertPrice, symbol } = useCurrencyConverter();
   const { enqueueSnackbar } = useSnackbar();

   const [coupon, setCoupon] = useState(couponCode);
   let finalPriceAfterCoupon

   const totalPrice = cartItems?.reduce(
      (sum, item) => sum + item?.price * (item?.quantity || 1),
      0
   );
    const handleApplyCoupon = async () => {
     try {
       if (coupon) {
         const response = await publicCommunication?.applyCoupon(coupon, totalPrice);
         const discountAmount = response?.data?.discountAmount;
         const finalAmountFromApi = response?.data?.totalPrice;
   
         if (response?.data?.success) {
           dispatch(applyCoupon({ couponCode:coupon, discount: discountAmount, finalAmount: finalAmountFromApi }));
           enqueueSnackbar(response?.data?.message, { variant: "success" });
         } else {
           dispatch(applyCoupon({ couponCode:coupon, discountAmount, finalAmount:totalPrice }));
           if (response?.data?.success === false) {
             enqueueSnackbar(response?.data?.message, { variant: "warning" });
             dispatch(clearCoupon());
           }
         }
       }
     } catch (error) {
       enqueueSnackbar(error?.message, { variant: "error" });
       dispatch(clearCoupon());
     }
   
   };

   const discountedPrice = cartItems?.reduce(
      (sum, item) =>
         sum +
         ((item?.cuttedPrice || 0) * (item?.quantity || 1) -
            item?.price * (item?.quantity || 1)),
      0
   );

   if (finalAmount) {
      finalPriceAfterCoupon = Math.max(finalAmount, 0);
   } else {
      finalPriceAfterCoupon = Math.max(totalPrice - discount, 0);

   }

   return (
      <div className="flex sticky top-16 lg:h-screen flex-col lg:w-4/12 lg:px-1 mb-10 lg:mg-0">
         {/* <!-- nav tiles --> */}
         <div className="flex flex-col bg-white rounded-sm shadow dark:bg-primary-black dark:text-primary-beige dark:border dark:border-gray-600">
            <h1 className="px-6 py-3 border-b font-medium text-gray-500 dark:border-gray-600">
               PRICE DETAILS
            </h1>

            <div className="flex flex-col gap-4 p-6 pb-3">
               <p className="flex justify-between">
                  Price ({cartItems?.length} item)
                  <span>
                     {`${symbol()} ${convertPrice(
                        cartItems?.reduce(
                           (sum, item) =>
                              sum +
                              (item?.cuttedPrice || 0) * (item?.quantity || 1),
                           0
                        )
                     )}`}
                  </span>
               </p>
               <p className="flex justify-between">
                  Discount
                  <span className="text-primary-green">
                     {`- ${symbol()} ${convertPrice(
                        cartItems?.reduce(
                           (sum, item) =>
                              sum +
                              ((item?.cuttedPrice || 0) *
                                 (item?.quantity || 1) -
                                 item?.price * (item?.quantity || 1)),
                           0
                        )
                     )}`}
                  </span>
               </p>
               <p className="flex justify-between">
                  Delivery Charges{" "}
                  <span className="text-primary-green">FREE</span>
               </p>

               {discount > 0 && (
                  <p className="flex justify-between">
                     Coupon Discount
                     <span className="text-primary-green">
                        {`- ${symbol()} ${convertPrice(discount)}`}
                     </span>
                  </p>
               )}

               <div className="border border-dashed dark:border-gray-600"></div>
               <p className="flex justify-between text-lg font-medium">
                  Total Amount
                  <span>{`${symbol()} ${convertPrice(finalPriceAfterCoupon)}`}</span>
               </p>
               <div className="border border-dashed dark:border-gray-600"></div>

               <p className="font-medium text-primary-green">
                  You will save
                  {`${symbol()} ${convertPrice(discountedPrice + discount)}`}
                  &nbsp;on this order
               </p>
               {/* Coupon Code Section */}
               {isCouponApply && (
                  <div className="flex flex-col gap-2 mt-4">
                     <input
                        type="text"
                        value={coupon}
                        onChange={(e) =>
                           setCoupon(e.target.value.toUpperCase())
                        }
                        placeholder="Enter Coupon Code"
                        className="p-2 border rounded dark:text-primary-beige dark:bg-transparent dark:border-gray-600"
                     />
                     <button
                        onClick={handleApplyCoupon}
                        className="p-2 bg-primary-green text-white rounded"
                     >
                        Apply Coupon
                     </button>
                  </div>
               )}
            </div>
         </div>
         {/* <!-- nav tiles --> */}
      </div>
   );
};

export default PriceSidebar;
