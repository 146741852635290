import { Skeleton } from "@mui/material";
import React from "react";
import "./components.css";

export default function LoadingScaletonCard({isLoading=true}) {
   return (
      <div className="LoadingScaletonCardWrapper">
        { [1,1,1,1]?.map((ele)=>{
            return <div className="flex flex-col gap-2">
            <Skeleton
               variant="rectangular"
               className="loadingScaletonCard1"
               animation={isLoading}
            />
            <Skeleton
               variant="rectangular"
               className="loadingScaletonCard2"
               animation={isLoading}
            />
            <Skeleton
               variant="rectangular"
               className="loadingScaletonCard2"
               animation={isLoading}
            />
         </div>
        })}
      </div>
   );
}
