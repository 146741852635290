import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const currencyApiKey = process.env.REACT_APP_CURRENCY_API_KEY

const currencySlice = createSlice({
   name: "currency",
   initialState: {
      exchangeRates: {},
      selectedCurrency: localStorage.getItem('currency') || 'INR'
   },
   reducers: {
      setExchangeRates: (state, action) => {
         state.exchangeRates = action.payload;
      },
      setSelectedCurrency: (state, action) => {
         state.selectedCurrency = action.payload;
         localStorage.setItem('currency', action.payload)
      },
   },
});

export const { setExchangeRates, setSelectedCurrency } = currencySlice.actions;

export const fetchExchangeRates = () => (dispatch) => {
   axios(`https://v6.exchangerate-api.com/v6/${currencyApiKey}/latest/INR`)
      .then((res) => {
         dispatch(setExchangeRates(res?.data?.conversion_rates));
      })
      .catch((err) => {
         console.error("Error fetching exchange rates:", err);
      });
};

export default currencySlice.reducer;
