import React from "react";
import MetaData from "../Layouts/MetaData";
import SidePattern from "../Home/SidePattern";
// import aboutPdf from "../../assets/images/about-page.pdf#toolbar=0";
import pinkFlagStrip from "../../assets/images/home/pink-flag-strip.png";
import Slider from "react-slick";
// images
import bannerImg from "../../assets/images/about/banner.png";
import aboutImg from "../../assets/images/about/about.png";
import historyImg from "../../assets/images/about/history.png";
import weaversImg from "../../assets/images/about/weavers.png";
import rangeImg from "../../assets/images/about/range.png";
import icon1 from "../../assets/images/about/icon1.png";
import icon2 from "../../assets/images/about/icon2.png";
import icon3 from "../../assets/images/about/icon3.png";

const About = () => {
   const aboutArray = [
      {
         icon: icon1,
         title: "Commitment",
         description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ea cupiditate enim quaerat voluptate et accusantium!",
      },
      {
         icon: icon2,
         title: "Consistency",
         description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ea cupiditate enim quaerat voluptate et accusantium!  consectetur adipisicing elit.",
      },
      {
         icon: icon3,
         title: "Contemporary Design",
         description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ea cupiditate enim quaerat voluptate et accusantium!  consectetur adipisicing elit. consectetur adipisicing elit.",
      },
   ];
   return (
      <>
         <MetaData title="About us | Mahahandloom" />
         {/* 1st section */}
         <section className="relative aspect-auto h-auto w-full overflow-hidden bg-primary-beige dark:bg-primary-black">
            {/* <section className="py-16 relative xl:mr-0 lg:mr-5 mr-0">
          <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
            <div className="w-full justify-start items-center xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
              <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                <div className="w-full flex-col justify-center items-start gap-8 flex">
                  <div className="flex-col justify-start lg:items-start items-center gap-4 flex">
                    <h6 className="text-primary-dark text-base font-medium leading-relaxed">
                      About Us
                    </h6>
                    <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                      <h2 className="text-primary-pink text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                        The Tale of Our Achievement Story
                      </h2>
                      <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                        Our achievement story is a testament to teamwork and
                        perseverance. Together, we've overcome challenges,
                        celebrated victories, and created a narrative of
                        progress and success.
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex-col justify-center items-start gap-6 flex">
                    <div className="w-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                      <div className="w-full h-full p-3.5 rounded-xl border border-gray-300 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                        <h4 className="text-primary-blue text-2xl font-bold font-manrope leading-9">
                          33+ Years
                        </h4>
                        <p className="text-gray-500 text-base font-normal leading-relaxed">
                          Influencing Digital Landscapes Together
                        </p>
                      </div>
                      <div className="w-full h-full p-3.5 rounded-xl border border-gray-300 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                        <h4 className="text-primary-blue text-2xl font-bold font-manrope leading-9">
                          125+ Projects
                        </h4>
                        <p className="text-gray-500 text-base font-normal leading-relaxed">
                          Excellence Achieved Through Success
                        </p>
                      </div>
                    </div>
                    <div className="w-full h-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                      <div className="w-full p-3.5 rounded-xl border border-gray-300 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                        <h4 className="text-primary-blue text-2xl font-bold font-manrope leading-9">
                          26+ Awards
                        </h4>
                        <p className="text-gray-500 text-base font-normal leading-relaxed">
                          Our Dedication to Innovation Wins Understanding
                        </p>
                      </div>
                      <div className="w-full h-full p-3.5 rounded-xl border border-gray-300 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                        <h4 className="text-primary-blue text-2xl font-bold font-manrope leading-9">
                          99% Happy Clients
                        </h4>
                        <p className="text-gray-500 text-base font-normal leading-relaxed">
                          Mirrors our Focus on Client Satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:justify-start justify-center items-start flex">
                <div className="sm:w-[564px] w-full sm:h-[646px] h-full sm:bg-primary-pink rounded-3xl sm:border border-gray-300 relative">
                  <img
                    className="sm:mt-5 sm:ml-5 w-full h-full rounded-3xl object-cover"
                    src="https://images.unsplash.com/photo-1719154717733-d37860cdee24?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8aGFuZGxvb218ZW58MHx8MHx8fDA%3D"
                    alt="about Us "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto py-24">
            <div className="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
              <div className="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
                <div className="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                  <img
                    className=" rounded-xl object-cover"
                    src="https://images.unsplash.com/photo-1610189025857-f42fe6e8dd91?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aGFuZGxvb20lMjBzYXJlZXxlbnwwfHwwfHx8MA%3D%3D"
                    alt="about Us "
                  />
                </div>
                <img
                  className="sm:ml-0 ml-auto rounded-xl object-cover"
                  src="https://images.unsplash.com/photo-1609748340041-f5d61e061ebc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8aGFuZGxvb20lMjBzYXJlZXxlbnwwfHwwfHx8MA%3D%3D"
                  alt="about Us "
                />
              </div>
              <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                <div className="w-full flex-col justify-center items-start gap-8 flex">
                  <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                    <h2 className="text-gray-900 text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                      Empowering Each Other to Succeed
                    </h2>
                    <p className="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                      Every project we've undertaken has been a collaborative
                      effort, where every person involved has left their mark.
                      Together, we've not only constructed buildings but also
                      built enduring connections that define our success story.
                    </p>
                  </div>
                  <div className="w-full lg:justify-start justify-center items-center sm:gap-10 gap-5 inline-flex">
                    <div className="flex-col justify-start items-start inline-flex">
                      <h3 className="text-primary-blue text-4xl font-bold font-manrope leading-normal">
                        33+
                      </h3>
                      <h6 className="text-gray-500 text-base font-normal leading-relaxed">
                        Years of Experience
                      </h6>
                    </div>
                    <div className="flex-col justify-start items-start inline-flex">
                      <h4 className="text-primary-blue text-4xl font-bold font-manrope leading-normal">
                        125+
                      </h4>
                      <h6 className="text-gray-500 text-base font-normal leading-relaxed">
                        Successful Projects
                      </h6>
                    </div>
                    <div className="flex-col justify-start items-start inline-flex">
                      <h4 className="text-primary-blue text-4xl font-bold font-manrope leading-normal">
                        52+
                      </h4>
                      <h6 className="text-gray-500 text-base font-normal leading-relaxed">
                        Happy Clients
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

            {/* left flower pattern */}
            <SidePattern
               className={"absolute left-0 dark:hidden"}
               side="left"
            />
            {/* right flower pattern  */}
            <SidePattern
               className={"absolute right-0 dark:hidden"}
               side="right"
            />
            <div className="about-us-top-header">
               <div
                  className={` p-10 flex items-center justify-center bg-cover`}
                  style={{ backgroundImage: `url(${bannerImg})`, minHeight:'300px' }}
               >
                  <div className="text-white ">
                     <h2 className="text-[35px] sm:text-[45px] lg:text-[56px] font-['Branch'] font-medium text-center">
                        Welcome to Maha Handloom
                     </h2>
                     <h1 className="text-center text-[16px] sm:text-[20px] lg:text-[24px] py-3">
                        Mahahandloom is a renowned clothing brand based in
                        nagpur, establish in 1971. We are celebrated for our
                        exquisite collection of authentic sarees, along with
                        enchanting clothing material and a luxury tie brand.
                     </h1>
                  </div>
               </div>
            </div>
         </section>

         {/* 2nd section  */}
         <section className="pattern-section relative aspect-auto w-full overflow-hidden dark:bg-primary-black bg-primary-beige">
            {/* left flower pattern */}
            <SidePattern
               className={"absolute left-0 dark:hidden"}
               side="left"
            />
            {/* right flower pattern  */}
            <SidePattern
               className={"absolute right-0 dark:hidden"}
               side="right"
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-20 p-10 lg:px-20 py-10">
               <div className="">
                  <h4 className="font-['Branch'] text-primary-pink text-[36px] sm:text-[46px] lg:text-7xl">
                     About Us
                  </h4>
                  <p className="text-[14px] sm:text-[20px] lg:text-[24px] font-light py-4 text-justify dark:text-primary-beige">
                     Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                     Fugit necessitatibus quis corporis, inventore maxime ipsa
                     placeat nulla aspernatur earum, nisi dolor ratione
                     reiciendis. Voluptas, repellendus dolorem est provident
                     cupiditate ad iusto sit corrupti hic consequatur! Soluta,
                     eaque recusandae sunt doloremque perspiciatis amet itaque
                     blanditiis voluptatum aliquam quae, excepturi natus ullam!
                     Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                     Atque aliquam adipisci soluta, eveniet maiores earum fuga
                     ipsum commodi? Exercitationem, illum. Lorem ipsum dolor sit
                     amet, consectetur adipisicing elit. Suscipit, aliquam.
                  </p>
               </div>
               <div>
                  <div className="relative m-10 before:content-[''] before:absolute before:top-0 before:left-0 before:border-[4px] before:border-primary-pink before:w-full before:h-full before:translate-x-[-25px] before:translate-y-4">
                     <Slider>
                        <img
                           src={aboutImg}
                           draggable={false}
                           alt=""
                           className="w-full h-auto object-contain shadow-2xl"
                        />
                     </Slider>
                  </div>
               </div>
            </div>
         </section>

         {/* 3rd section  */}
         <section className="dark:bg-primary-black pattern-section relative aspect-auto w-full overflow-hidden">
            {/* left flower pattern */}
            <SidePattern
               className={"absolute left-0 dark:hidden"}
               side="left"
            />
            {/* right flower pattern  */}
            <SidePattern
               className={"absolute right-0 dark:hidden"}
               side="right"
            />
            <div className="h-full">
               <div
                  className="px-10 py-40 lg:px-28 flex align-center justify-center h-full bg-cover"
                  style={{ backgroundImage: `url(${historyImg})` }}
               >
                  <div className="text-white w-[100%] lg:w-[50%] ml-auto">
                     <h4 className="font-['Branch'] text-[38px] sm:text-[46px] lg:text-7xl text-right capitalize">
                        History of Mahahandloom
                     </h4>
                     <p className="text-[16px] sm:text-[20px] lg:text-[24px] font-light text-justify py-3">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Molestiae nobis nisi vitae praesentium, quis voluptates
                        aperiam sint corrupti eum sit temporibus quo, accusamus
                        dicta voluptate aspernatur. Perferendis ea ipsam esse
                        est! Reprehenderit illo quae amet nesciunt natus
                        explicabo dolore quod pariatur alias corrupti voluptatem
                        corporis eius, dicta libero, unde quibusdam!
                     </p>
                  </div>
               </div>
            </div>
         </section>

         {/* 4th section  */}
         <section className="pattern-section relative aspect-auto w-full overflow-hidden dark:bg-primary-black">
            {/* left flower pattern */}
            <SidePattern
               className={"absolute left-0 dark:hidden"}
               side="left"
            />
            {/* right flower pattern  */}
            <SidePattern
               className={"absolute right-0 dark:hidden"}
               side="right"
            />
            <img
               src={pinkFlagStrip}
               className="h-full w-full object-contain"
               draggable={false}
               alt=""
            />
            <div className="px-10 py-20 lg:px-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
               {aboutArray?.map((data, index) => (
                  <div key={index}>
                     <img
                        draggable="false"
                        className="h-12 w-12 sm:h-18 sm:w-18 lg:h-24 lg:w-24 mx-auto"
                        src={data?.icon}
                        alt="Mahahandloom.com mahahandloom"
                     />
                     <h6 className="font-['Branch'] text-[22px] sm:text-[26px] lg:text-4xl text-primary-blue dark:text-primary-beige font-medium text-center pt-3">
                        {data?.title}
                     </h6>
                     <p className="text-center text-[14px] sm:text-[18px] lg:text-[20px] text-gray-700 dark:text-primary-lightBeige font-light py-2">
                        {data?.description}
                     </p>
                  </div>
               ))}
            </div>
            <img
               src={pinkFlagStrip}
               className="h-full w-full object-contain rotate-180"
               draggable={false}
               alt=""
            />
         </section>

         {/* 5th section  */}
         <section className=" pattern-section relative aspect-auto w-full overflow-hidden dark:bg-primary-black">
            {/* left flower pattern */}
            <SidePattern
               className={"absolute left-0 dark:hidden"}
               side="left"
            />
            {/* right flower pattern  */}
            <SidePattern
               className={"absolute right-0 dark:hidden"}
               side="right"
            />
            <div>
               <div
                  className="px-10 pt-8 pb-64 lg:px-28 bg-cover bg-left"
                  style={{ backgroundImage: `url(${weaversImg})`, minHeight:'800px', display:'flex', alignItems:'center'}}
               >
                     <div className="text-white w-[100%] lg:w-[60%]" style={{maxHeight:'content-fit'}}>
                        <h4 className="font-['Branch'] text-[38px] sm:text-[46px] lg:text-7xl capitalize">
                           Meet Our Weavers
                        </h4>
                        <p className="text-[16px] sm:text-[20px] lg:text-[24px] font-light text-justify py-3">
                           Lorem ipsum dolor sit amet consectetur adipisicing
                           elit. Molestiae nobis nisi vitae praesentium, quis
                           voluptates aperiam sint corrupti eum sit temporibus
                           quo, accusamus dicta voluptate aspernatur.
                           Perferendis ea ipsam esse est! Reprehenderit illo
                           quae amet nesciunt natus explicabo dolore quod
                           pariatur alias corrupti voluptatem corporis eius,
                           dicta libero, unde quibusdam!
                        </p>
                     </div>
               </div>
            </div>
         </section>

         {/* 6th & Last section  */}
         <section className="pattern-section relative aspect-auto w-full overflow-hidden dark:bg-primary-black bg-primary-beige">
            {/* left flower pattern */}
            <SidePattern
               className={"absolute left-0 dark:hidden"}
               side="left"
            />
            {/* right flower pattern  */}
            <SidePattern
               className={"absolute right-0 dark:hidden"}
               side="right"
            />
            <div className="flex items-center flex-col flex-col-reverse lg:flex-row gap-10 lg:gap-20 px-10  pb-20 lg:px-28">
               <div className="w-[60%] relative before:content-[''] before:absolute before:top-0 before:left-0 before:border-[4px] before:border-primary-pink before:w-full before:h-full before:translate-x-[25px] before:translate-y-4">
                  <Slider>
                     <img
                        src={rangeImg}
                        draggable={false}
                        alt=""
                        className="w-full h-auto object-contain shadow-2xl"
                     />
                  </Slider>
               </div>
               <div>
                  <h4 className="font-['Branch'] text-primary-pink text-[38px] sm:text-[46px] lg:text-7xl">
                     Our Product Range
                  </h4>
                  <p className="text-[16px] sm:text-[20px] lg:text-[24px] font-light py-4 text-justify dark:text-primary-beige">
                     Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                     Fugit necessitatibus quis corporis, inventore maxime ipsa
                     placeat nulla aspernatur earum, nisi dolor ratione
                     reiciendis. Voluptas, repellendus dolorem est provident
                     cupiditate ad iusto sit corrupti hic consequatur! Soluta,
                     eaque recusandae sunt doloremque
                  </p>
               </div>
            </div>
         </section>
      </>
   );
};

export default About;
