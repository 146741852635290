// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingScaletonCardWrapper {
   display: flex !important;
   flex-wrap: wrap !important;
   gap: 22px !important;
   align-items: center !important;
   justify-content: center !important;
   padding: 30px 50px !important;
}
.loadingScaletonCard1 {
   width: 240px !important;
   height: 240px !important;
   background-color: rgba(193, 179, 148, 0.3) !important;
   border-radius: 10px;
}
.loadingScaletonCard2 {
   width: 200px !important;
   height: 10px !important;
   background-color: rgba(193, 179, 148, 0.3) !important;
   border-radius: 10px;
}

@media (max-width: 1200px) {

}
@media (max-width: 900px) {
   .LoadingScaletonCardWrapper {
      padding: 30px 26px;
   }
}
@media (max-width: 768px) {
   .loadingScaletonCard1 {
      width: 190px !important;
      height: 180px !important;
   }
   .loadingScaletonCard2 {
      width: 140px !important;
   }
}
@media (max-width: 500px) {
}
`, "",{"version":3,"sources":["webpack://./src/components/components.css"],"names":[],"mappings":"AAAA;GACG,wBAAwB;GACxB,0BAA0B;GAC1B,oBAAoB;GACpB,8BAA8B;GAC9B,kCAAkC;GAClC,6BAA6B;AAChC;AACA;GACG,uBAAuB;GACvB,wBAAwB;GACxB,qDAAqD;GACrD,mBAAmB;AACtB;AACA;GACG,uBAAuB;GACvB,uBAAuB;GACvB,qDAAqD;GACrD,mBAAmB;AACtB;;AAEA;;AAEA;AACA;GACG;MACG,kBAAkB;GACrB;AACH;AACA;GACG;MACG,uBAAuB;MACvB,wBAAwB;GAC3B;GACA;MACG,uBAAuB;GAC1B;AACH;AACA;AACA","sourcesContent":[".LoadingScaletonCardWrapper {\n   display: flex !important;\n   flex-wrap: wrap !important;\n   gap: 22px !important;\n   align-items: center !important;\n   justify-content: center !important;\n   padding: 30px 50px !important;\n}\n.loadingScaletonCard1 {\n   width: 240px !important;\n   height: 240px !important;\n   background-color: rgba(193, 179, 148, 0.3) !important;\n   border-radius: 10px;\n}\n.loadingScaletonCard2 {\n   width: 200px !important;\n   height: 10px !important;\n   background-color: rgba(193, 179, 148, 0.3) !important;\n   border-radius: 10px;\n}\n\n@media (max-width: 1200px) {\n\n}\n@media (max-width: 900px) {\n   .LoadingScaletonCardWrapper {\n      padding: 30px 26px;\n   }\n}\n@media (max-width: 768px) {\n   .loadingScaletonCard1 {\n      width: 190px !important;\n      height: 180px !important;\n   }\n   .loadingScaletonCard2 {\n      width: 140px !important;\n   }\n}\n@media (max-width: 500px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
