import WebFont from "webfontloader";
import Login from "./components/User/Login";
import Register from "./components/User/Register";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpdatePassword from "./components/User/UpdatePassword";
import ForgotPassword from "./components/User/ForgotPassword";
import ResetPassword from "./components/User/ResetPassword";
import Account from "./components/User/Account";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Home from "./components/Home/Home";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import Products from "./components/Products/Products";
import Cart from "./components/Cart/Cart";
import Shipping from "./components/Cart/Shipping";
import OrderConfirm from "./components/Cart/OrderConfirm";
import Payment from "./components/Cart/Payment";
import OrderStatus from "./components/Cart/OrderStatus";
import OrderSuccess from "./components/Cart/OrderSuccess";
import MyOrders from "./components/Order/MyOrders";
import OrderDetails from "./components/Order/OrderDetails";
import Dashboard from "./components/Admin/Dashboard";
import MainData from "./components/Admin/MainData";
import OrderTable from "./components/Admin/OrderTable";
import UpdateOrder from "./components/Admin/UpdateOrder";
import ProductTable from "./components/Admin/ProductTable";
import NewProduct from "./components/Admin/NewProduct";
import UpdateProduct from "./components/Admin/UpdateProduct";
import UserTable from "./components/Admin/UserTable";
import UpdateUser from "./components/Admin/UpdateUser";
import ReviewsTable from "./components/Admin/ReviewsTable";
import Wishlist from "./components/Wishlist/Wishlist";
import NotFound from "./components/NotFound";
import "./fonts/Branch.ttf";
import TopHeader from "./components/Layouts/Header/TopHeader";
import Contact from "./components/contact/Contact";
import About from "./components/about/About";
import Category from "./components/Admin/Category";
import AddCategory from "./components/Admin/NewCategory";
import AddSubcategory from "./components/Admin/NewSubcategory";
import Subcategory from "./components/Admin/SubCategory";
import UpdateCategory from "./components/Admin/UpdateCategory";
import UpdateSubcategory from "./components/Admin/UpdateSubCategory";
import Department from "./components/Admin/Department";
import AddDepartment from "./components/Admin/NewDepartment";
import SubDepartment from "./components/Admin/SubDepartment";
import AddSubDepartment from "./components/Admin/NewSubDepartment";
import UpdateDepartment from "./components/Admin/UpdateDepartment";
import UpdateSubDepartment from "./components/Admin/UpdateSubDepartment";
import { fetchWishlistItems } from "./store/wishlistSlice";
import { fetchCartItems } from "./store/cartSlice";
import AdminProtectedRoute from "./Routes/AdminProtectedRoute";
import CreateDepartmentUser from "./components/Admin/CreateDepartmentUser";
import DepartmentUsers from "./components/Admin/DepartmentUsers";
import AddDepartmentUser from "./components/Admin/NewDepartmentUser";
import { fetchSaveForLater } from "./store/saveForLaterSlice";
import { fetchUserDetails } from "./store/userSlice";
import UpdateDepartmentUser from "./components/Admin/UpdateDepartmentUser";
import Coupon from "./components/Admin/Coupons";
import AddCoupon from "./components/Admin/NewCoupon";
import UpdateCoupon from "./components/Admin/UpdateCoupon";
import DeptLogin from "./components/Admin/DeptLogin";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);
  // const [stripeApiKey, setStripeApiKey] = useState("");

  // async function getStripeApiKey() {
  //   const { data } = await axios.get('/api/v1/stripeapikey');
  //   setStripeApiKey(data.stripeApiKey);
  // }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Poppins:300,400,500,600,700"],
      },
    });
  });

  // fetch user details on initial laod
  useEffect(() => {
    dispatch(fetchUserDetails());
    // getStripeApiKey();
  }, []);

  // fetch user product items on initial load
  useEffect(() => {
    // if user is login then we call Cart,save for later and wishlist api
    if (user) {
      // Fetch cart items on component mount
      dispatch(fetchCartItems());
      // Fetch save for later items on component mount
      dispatch(fetchSaveForLater());
      // Fetch wishlist items on component mount
      dispatch(fetchWishlistItems());
    }
  }, [user]);

  // always scroll to top on route/path change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  // disable right click
  window.addEventListener("contextmenu", (e) => e.preventDefault());

  window.addEventListener("keydown", (e) => {
    // Prevent F12 (Developer Tools)
    // if (e.key === "F12") e.preventDefault();

    // Prevent Ctrl+Shift+I (Inspect Element)
    if (e.ctrlKey && e.shiftKey && e.key === "I") e.preventDefault();

    // Prevent Ctrl+Shift+J (Console)
    if (e.ctrlKey && e.shiftKey && e.key === "J") e.preventDefault();
  });

  return (
    <>
      <Routes>
        {/* Note: Topheader is Shared Component that shared Topheader with Navbar, footer, Chatbot and Fixedstats component inside  */}
        {/* shared route  */}
        <Route path="/" element={<TopHeader />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="product/:id" element={<ProductDetails />} />
          <Route path="products" element={<Products />} />
          <Route path="products/:keyword" element={<Products />} />
          <Route path="cart" element={<Cart />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="password/forgot" element={<ForgotPassword />} />
          <Route
            path="password/update"
            element={
              <ProtectedRoute>
                <UpdatePassword />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="wishlist"
            element={
              <ProtectedRoute>
                <Wishlist />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="orders"
            element={
              <ProtectedRoute>
                <MyOrders />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="order_details/:id"
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/shipping"
            element={
              <ProtectedRoute>
                <Shipping />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/order/confirm"
            element={
              <ProtectedRoute>
                <OrderConfirm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/process/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/orders/success"
            element={<OrderSuccess success={true} />}
          />
          <Route
            path="/orders/failed"
            element={<OrderSuccess success={false} />}
          />
        </Route>
        {/* shared route  */}

         <Route
          path="/order/:id"
          element={
            <ProtectedRoute>
              <OrderStatus />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/dept/login"
          element={
            // <ProtectedRoute>
              <DeptLogin />
            // </ProtectedRoute>
          }
        ></Route>

        {/* <Route path="/password/reset/:token" element={<ResetPassword />} /> */}

        {/* Admin Routes  */}
        <Route
          path="/admin/dashboard"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={0}>
                <MainData />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/orders"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={1}>
                <OrderTable />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/order/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={1}>
                <UpdateOrder />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/products"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={2}>
                <ProductTable />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/new_product"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={2}>
                <NewProduct />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/product/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={2}>
                <UpdateProduct />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/category"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={3}>
                <Category />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/category/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={3}>
                <UpdateCategory />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/new_category"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={3}>
                <AddCategory />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/subcategory"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={4}>
                <Subcategory />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/new_subcategory"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={4}>
                <AddSubcategory />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/subcategory/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={4}>
                <UpdateSubcategory />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/users"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={5}>
                <UserTable />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/user/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={5}>
                <UpdateUser />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        <Route
          path="/admin/reviews"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={6}>
                <ReviewsTable />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/department"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={7}>
                <Department />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/new_department"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={7}>
                <AddDepartment />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/department/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={7}>
                <UpdateDepartment />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/subdepartment"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={8}>
                <SubDepartment />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/new_subdepartment"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={8}>
                <AddSubDepartment />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/subdepartment/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={8}>
                <UpdateSubDepartment />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/department-users"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={9}>
                <DepartmentUsers />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/new_department_users"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={9}>
                <AddDepartmentUser />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/department-users/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={9}>
                <UpdateDepartmentUser />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/coupons"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={10}>
                <Coupon />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/new_coupon"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={10}>
                <AddCoupon />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>
        <Route
          path="/admin/coupons/:id"
          element={
            <AdminProtectedRoute>
              <Dashboard activeTab={10}>
                <UpdateCoupon />
              </Dashboard>
            </AdminProtectedRoute>
          }
        ></Route>

        {/* Admin Routes  */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
