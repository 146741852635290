import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import StarIcon from "@mui/icons-material/Star";
import { useEffect, useState } from "react";
import publicCommunication from "../service/publicCommunication";
import { useSnackbar } from "notistack";

const FixedStats = () => {
   const { enqueueSnackbar } = useSnackbar();
   const [visitorsCount, setVisitorsCount] = useState();
   const [ratingCount, setRatingCounts] = useState();

   const getVisitorsList = async () => {
      try {
         const response = await publicCommunication?.getVisitors();
         setVisitorsCount(response?.data?.visitors);
      } catch (error) {
         enqueueSnackbar("Error fetching accessories: " + error.message, {
            variant: "error",
         });
      }
   };
   const getRatings = async () => {
      try {
         const response = await publicCommunication?.getRatings();
         setRatingCounts(response?.data?.roundedRating);
      } catch (error) {
         enqueueSnackbar("Error fetching accessories: " + error.message, {
            variant: "error",
         });
      }
   };

   useEffect(() => {
      getVisitorsList();
      getRatings();
   }, []);

   return (
      <>
         {/* no of visitors  */}
         <div className="fixed top-[50%] translate-y-[-50%] border right-0 z-50 bg-white dark:bg-primary-black dark:border-gray-600 rounded-l-md cursor-pointer group transition-all translate-x-[75%] hover:translate-x-0">
            <p className="text-sm fw-medium text-center p-2 flex items-center gap-1">
               <RemoveRedEyeIcon className="text-primary-blue" />
               <span className="text-primary-pink px-3">{`${
                  visitorsCount ? visitorsCount : 0
               } Visitors`}</span>
            </p>
         </div>
         {/* google review  */}
         <div
            className="fixed top-[50%] translate-y-[-50%] border left-0 z-50 bg-white dark:bg-primary-black dark:border-gray-600 rounded-r-md cursor-pointer group transition-all translate-x-[-70%] hover:translate-x-0"
            title={`${ratingCount ? ratingCount : 0} Overall Customer Ratings`}
         >
            <p className="text-sm fw-medium text-center p-2 flex items-center gap-1">
               <span className="text-primary-pink px-1 hover:underline">
                  {`${ratingCount ? ratingCount : 0} Ratings`} 
               </span>
               <StarIcon className="text-yellow-300" />
            </p>
         </div>
      </>
   );
};

export default FixedStats;
