import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceSidebar from "./PriceSidebar";
import Stepper from "./Stepper";
import { clearErrors } from "../../actions/orderAction";
import MetaData from "../Layouts/MetaData";
import publicCommunication from "../../service/publicCommunication";
import { useNavigate } from "react-router-dom";
import {
   newOrderFail,
   newOrderRequest,
   newOrderSuccess,
} from "../../store/orderSlice";
import { applyCoupon, removeAllCartItemsApi } from "../../store/cartSlice";
import { useCurrencyConverter } from "../../utils/useCurrencyConverter";
import { useSnackbar } from "notistack";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import logo from '../../assets/images/home/Logo.png'

const Payment = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const { convertPrice, symbol, selectedCurrency } = useCurrencyConverter();
   const [payDisable, setPayDisable] = useState(false);
   const { shippingInfo, cartItems } = useSelector((state) => state?.cart);
   const { user } = useSelector((state) => state?.user);
   const { error } = useSelector((state) => state?.newOrder);
   const { discount = 0,couponCode } = useSelector((state) => state.cart);
   const razorPayKey = process.env.REACT_APP_RAZORPAY_API_KEY;
   const { isLoading, Razorpay } = useRazorpay();
   
   const totalPrice = cartItems?.reduce(
      (sum, item) => sum + item?.price * (item?.quantity || 1),
      0
   );

   const finalAmount = Math.max(totalPrice - discount, 0);

   const userShippingInfo = {
      name: user?.name,
      email: user?.email,
      phoneNo: shippingInfo?.phoneNo,
   };

   const orderDetails = {
      shippingInfo,
      orderItems: cartItems?.map((data, i) => ({
         name: data?.name,
         price: data?.price,
         quantity: data?.quantity || 1,
         image: data?.brand?.logo?.url,
         product: data?._id,
      })),
      totalPrice: finalAmount,
      couponCode
   };

   const submitHandler = async () => {
      setPayDisable(true);
      try {
         dispatch(newOrderRequest());
         const serverResponse = await publicCommunication.newOrder(
            orderDetails
         );
         if (serverResponse?.data?.success) {
            dispatch(newOrderSuccess(serverResponse?.data?.order));
             dispatch(applyCoupon({ couponCode:null}));
            dispatch(removeAllCartItemsApi()); // clear cart after order success
            navigate("/orders/success");
         } else {
            setPayDisable(false);
            dispatch(
               newOrderFail(
                  serverResponse?.data?.message || "Processing Payment Failed!"
               )
            );
            enqueueSnackbar(
               serverResponse?.data?.message || "Something went wrong!",
               { variant: "error" }
            );
         }
      } catch (error) {
         setPayDisable(false);
         dispatch(newOrderFail(error || "Processing Payment Failed!"));
         enqueueSnackbar(error || "Processing Payment Failed!", {
            variant: "error",
         });
         navigate("/orders/failed");
      }
   };

   const payPayment = (e) => {
      e.preventDefault();

      // Check if Razorpay key is available in the environment variables
      if (!razorPayKey) {
         enqueueSnackbar("Razorpay key is not set in environment variables.");
         return;
      }

      // Convert the amount to paise (100 paise = 1 INR)
      const convertedAmount =
         Number(convertPrice(finalAmount)) === 0
            ? 1 * 100
            : Number(convertPrice(finalAmount)) * 100;

      if (!convertedAmount) {
         enqueueSnackbar("Invalid amount");
         return;
      }
      // Define the options for Razorpay
      const options = {
         key: razorPayKey,
         amount: convertedAmount,
         currency: selectedCurrency || "INR",
         name: "Maha Handloom",
         description: `Transaction for your order`,
         image: logo,
         prefill: {
            name: userShippingInfo?.name || "",
            email: userShippingInfo?.email ?? "",
            contact: userShippingInfo?.mobile ?? "",
         },
         theme: { color: "#6364e0" },
         handler: function (data) {
            try {
               if (data?.razorpay_payment_id) {
                  orderDetails.paymentInfo = {
                     id: data?.razorpay_payment_id,
                     status: "Success",
                  };
                  submitHandler();
               } else {
                  enqueueSnackbar(
                     "Payment ID is missing. Please contact support."
                  );
               }
            } catch (error) {
               enqueueSnackbar(
                  error.message ||
                     "An error occurred during payment processing.",
                  {
                     icon: "⚠️",
                  }
               );
               console.error(error);
            }
         },
         modal: {
            escape: false,
         },
      };

      if (typeof Razorpay == "undefined") {
         enqueueSnackbar(
            "Razorpay SDK is not loaded. Please check your configuration."
         );
         return;
      }

      try {
         const razorpayInstance = new Razorpay(options);
         if (!razorpayInstance) {
            enqueueSnackbar("Failed to initialize Razorpay instance.");
            return;
         }
         razorpayInstance.open();
      } catch (error) {
         enqueueSnackbar(
            "Failed to initialize Razorpay. Please try again later."
         );
         console.error(error);
      }
   };

   useEffect(() => {
      if (error) {
         dispatch(clearErrors());
         enqueueSnackbar(error, { variant: "error" });
      }
   }, [dispatch, error, enqueueSnackbar]);

   return (
      <>
         <MetaData title="Flipkart: Secure Payment | Paytm" />

         <main className="w-full pt-3 dark:bg-primary-black">
            {/* <!-- row --> */}
            <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
               {/* <!-- cart column --> */}
               <div className="flex-1">
                  <Stepper activeStep={3}>
                     <div className="w-full bg-white dark:bg-primary-black dark:border dark:border-gray-600">
                        <form
                           onSubmit={(e) => {
                              payPayment(e);
                           }}
                           autoComplete="off"
                           className="flex flex-col justify-start gap-2 w-full mx-8 my-4 overflow-hidden"
                        >
                           <input
                              type="submit"
                              value={`Pay ${symbol()}${convertPrice(
                                 finalAmount
                              )}`}
                              disabled={payDisable ? true : false}
                              className={`${
                                 payDisable
                                    ? "bg-primary-grey cursor-not-allowed"
                                    : "bg-primary-blue cursor-pointer"
                              } w-1/2 sm:w-1/4 my-2 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none`}
                           />
                        </form>
                     </div>
                  </Stepper>
               </div>

               <PriceSidebar cartItems={cartItems} />
            </div>
         </main>
      </>
   );
};

export default Payment;
