// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainAdminWrapper{
    display: flex;
}

.adminPageWrapper{
    width: 78%;
    overflow-x: hidden;
    transition: 0.1s all ease-in-out;
}

.adminPageWrapperClosed{
    width: 92%;
    transition: 0.4s all ease-in-out;
}


/* -----------Media query ------------- */
@media (max-width:1300px) {
    .adminPageWrapper{
        width: 72%;
    }

    .adminPageWrapperClosed{
        width: 90%;
    }
}

@media (max-width:1024px) {
    .adminPageWrapper{
        width: 100%;
    }
    .adminPageWrapperClosed{
        width: 88%;
    }
}
@media (max-width:850px) {
    .adminPageWrapperClosed{
        width: 86%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/admin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,UAAU;IACV,gCAAgC;AACpC;;;AAGA,yCAAyC;AACzC;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".mainAdminWrapper{\n    display: flex;\n}\n\n.adminPageWrapper{\n    width: 78%;\n    overflow-x: hidden;\n    transition: 0.1s all ease-in-out;\n}\n\n.adminPageWrapperClosed{\n    width: 92%;\n    transition: 0.4s all ease-in-out;\n}\n\n\n/* -----------Media query ------------- */\n@media (max-width:1300px) {\n    .adminPageWrapper{\n        width: 72%;\n    }\n\n    .adminPageWrapperClosed{\n        width: 90%;\n    }\n}\n\n@media (max-width:1024px) {\n    .adminPageWrapper{\n        width: 100%;\n    }\n    .adminPageWrapperClosed{\n        width: 88%;\n    }\n}\n@media (max-width:850px) {\n    .adminPageWrapperClosed{\n        width: 86%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
