import pattern from "../../assets/images/home/side-flower-pattern.png";
import './home.css'

const SidePattern = ({ className, side }) => {
  return (
    <div className={`sidePattern ${className}`}>
      <img
        draggable="false"
        className={`h-full w-9/12 object-contain${
          side === "left" ? "mr-auto" : "ml-auto"
        }`}
        src={pattern}
        alt="Mahahandloom.com mahahandloom"
      />
    </div>
  );
};

export default SidePattern;
