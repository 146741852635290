import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { getDeliveryDate, getDiscount } from "../../utils/functions";
import { Link } from "react-router-dom";
import blank_img from "../../assets/images/blank_img.jpeg";
import {
  removeCartItemApi,
  updateCartQuantityApi,
} from "../../store/cartSlice";
import { addSaveForLaterApi } from "../../store/saveForLaterSlice";
import { useCurrencyConverter } from "../../utils/useCurrencyConverter";

const CartItem = ({
  product,
  id,
  name,
  seller,
  price,
  cuttedPrice,
  image,
  stock,
  quantity,
  inCart,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { convertPrice , symbol} = useCurrencyConverter();
  const increaseQuantity = (id, quantity, stock) => {
    const newQty = quantity + 1;
    if (quantity >= stock) {
      enqueueSnackbar("Maximum Order Quantity", { variant: "warning" });
      return;
    }
    dispatch(updateCartQuantityApi(id, newQty));
  };

  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1;
    if (quantity <= 1) return;
    dispatch(updateCartQuantityApi(id, newQty));
  };

  const removeCartItem = (id) => {
    dispatch(removeCartItemApi(id));
    enqueueSnackbar("Product removed from cart", { variant: "success" });
  };

  const saveForLaterHandler = (id) => {
    dispatch(addSaveForLaterApi(product, quantity));
    removeCartItem(id);
    enqueueSnackbar("Saved For Later", { variant: "success" });
  };

  return (
    <div
      className="flex flex-col gap-3 py-5 pl-2 sm:pl-6 border-b overflow-hidden dark:text-primary-beige dark:border-gray-600"
      key={id}
    >
      <Link
        to={`/product/${id}`}
        className="flex flex-col sm:flex-row gap-5 items-stretch w-full group"
      >
        {/* <!-- product image --> */}
        <div className="w-full sm:w-1/6 h-28 flex-shrink-0">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={image ?? blank_img}
            alt={name}
          />
        </div>
        {/* <!-- product image --> */}

        {/* <!-- description --> */}
        <div className="flex flex-col sm:gap-5 w-full pr-6">
          {/* <!-- product title --> */}
          <div className="flex flex-col sm:flex-row justify-between items-start pr-5 gap-3 sm:gap-0">
            <div className="flex flex-col gap-0.5 sm:w-3/5">
              <p className="group-hover:text-primary-blue dark:group-hover:text-blue-600  capitalize">
                {name?.length > 42 ? `${name?.substring(0, 42)}...` : name}
              </p>
              <span className="text-sm text-gray-500">Seller: {seller}</span>
            </div>

            <div className="flex flex-col sm:gap-2">
              <p className="text-sm">
                Delivery by {getDeliveryDate()}&nbsp;
                <span className="text-primary-green">Free</span>
                &nbsp;
                <span className="line-through">{`${symbol()} ${convertPrice(quantity * 40)}`}</span>
              </p>
              <span className="text-xs text-gray-500">
                7 Days Replacement Policy
              </span>
            </div>
          </div>
          {/* <!-- product title --> */}

          {/* <!-- price desc --> */}
          <div className="flex items-baseline gap-2 text-xl font-medium">
            <span>{`${symbol()} ${convertPrice(price * quantity)}`}</span>
            <span className="text-sm text-gray-500 line-through font-normal">
            {`${symbol()} ${convertPrice(cuttedPrice * quantity)}`}
            </span>
            <span className="text-sm text-primary-green">
              {getDiscount(price, cuttedPrice)}%&nbsp;off
            </span>
          </div>
          {/* <!-- price desc --> */}
        </div>
        {/* <!-- description --> */}
      </Link>

      {/* <!-- save for later --> */}
      <div className="flex justify-between pr-4 sm:pr-0 sm:justify-start sm:gap-6">
        {/* <!-- quantity --> */}
        <div className="flex gap-1 items-center">
          <span
            onClick={() => decreaseQuantity(id, quantity)}
            className="w-7 h-7 text-3xl font-light bg-gray-50 dark:bg-transparent rounded-full border flex items-center justify-center cursor-pointer"
          >
            <p>-</p>
          </span>
          <input
            className="w-11 border outline-none text-center rounded-sm py-0.5 text-gray-700 dark:bg-primary-beige font-medium text-sm qtyInput"
            value={quantity}
            disabled
          />
          <span
            onClick={() => increaseQuantity(id, quantity, stock)}
            className="w-7 h-7 text-xl font-light bg-gray-50 dark:bg-transparent rounded-full border flex items-center justify-center cursor-pointer"
          >
            +
          </span>
        </div>
        {/* <!-- quantity --> */}
        {inCart && (
          <>
            <button
              onClick={() => saveForLaterHandler(id)}
              className="sm:ml-4 font-medium hover:text-primary-blue dark:hover:text-blue-600"
            >
              SAVE FOR LATER
            </button>
            <button
              onClick={() => removeCartItem(id)}
              className="font-medium hover:text-red-600"
            >
              REMOVE
            </button>
          </>
        )}
      </div>
      {/* <!-- save for later --> */}
    </div>
  );
};

export default CartItem;
