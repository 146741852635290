export const currencies = [
  {
    currencyName: "INR",
    symbol: "₹",
  },
  {
    currencyName: "USD",
    symbol: "$",
  },
  {
    currencyName: "EUR",
    symbol: "€",
  },
];
