import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useNavigate } from "react-router-dom";
import { getDiscount } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlistApi,
  removeFromWishlistApi,
} from "../../store/wishlistSlice";
import { useSnackbar } from "notistack";
import blank_img from "../../assets/images/blank_img.jpeg";
import { useCurrencyConverter } from "../../utils/useCurrencyConverter";
import './products.css'

const Product = ({
  product,
  _id,
  name,
  images,
  ratings,
  numOfReviews,
  price,
  cuttedPrice,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { convertPrice , symbol} = useCurrencyConverter();

  const itemInWishlist = wishlistItems?.some((i) => i._id === _id);

  const addToWishlistHandler = () => {
    if (user) {
      if (itemInWishlist) {
        dispatch(removeFromWishlistApi(_id, user?._id));
        enqueueSnackbar("Remove From Wishlist", { variant: "success" });
      } else {
        dispatch(addToWishlistApi(product, user?._id));
        enqueueSnackbar("Added To Wishlist", { variant: "success" });
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      className="flex flex-col items-start gap-2 relative dark:bg-primary-black dark:border-gray-600 border border-gray-300 hover:shadow-lg hover:bg-white rounded-sm productCard"
      key={_id}
    >
      {/* <!-- image & product title --> */}
      <Link
        to={`/product/${_id}`}
        className="flex flex-col items-start text-left group w-[100%]"
      >
        <div className="m-auto">
          <img
            draggable="false"
            loading="lazy"
            src={images ?? blank_img}
            alt=""
          />
        </div>
        <h2 className="text-sm mt-4 group-hover:text-primary-blue dark:group-hover:text-blue-600 dark:text-primary-beige text-left capitalize">
          {name?.length > 60 ? `${name?.substring(0, 60)}...` : name}
        </h2>
      </Link>
      {/* <!-- image & product title --> */}

      {/* <!-- product description --> */}
      <div className="flex flex-col gap-2 items-start">
        {/* <!-- rating badge --> */}
        <span className="text-sm text-gray-500 font-medium flex gap-2 items-center">
          <span className="text-xs px-1.5 py-0.5 bg-primary-green rounded-sm text-white flex items-center gap-0.5">
            {ratings.toFixed(1)} <StarIcon sx={{ fontSize: "14px" }} />
          </span>
          <span>({numOfReviews})</span>
        </span>
        {/* <!-- rating badge --> */}

        {/* <!-- price container --> */}
        <div className="flex items-center gap-1.5 text-md font-medium flex-wrap">
          <span className="text-black dark:text-primary-beige">
            {`${symbol()} ${convertPrice(price)}`}
          </span>
          <span className="text-gray-500 line-through text-xs">
          {`${symbol()} ${convertPrice(cuttedPrice)}`}
          </span>
          <span className="text-xs text-primary-green">
            {getDiscount(price, cuttedPrice)}%&nbsp;off
          </span>
        </div>
        <div className="flex items-center gap-3">
          <Link to={`/product/${_id}`}>
            <button className="cursor-pointer bg-primary-pink text-white text-sm py-1 px-2 rounded-sm">
              Shop now
            </button>
          </Link>
        </div>
        {/* <!-- price container --> */}
      </div>
      {/* <!-- product description --> */}

      {/* <!-- wishlist badge --> */}
      <span
        onClick={addToWishlistHandler}
        className={`${
          itemInWishlist ? "text-red-500" : "hover:text-red-500 text-gray-300"
        } absolute top-6 right-6 cursor-pointer`}
      >
        <FavoriteIcon sx={{ fontSize: "18px" }} />
      </span>
      {/* <!-- wishlist badge --> */}
    </div>
  );
};

// {/* Product Description */ }
// <div className="flex flex-col gap-2 items-start">
//   {/* Rating Badge */}
//   <span className="text-sm text-gray-500 font-medium flex gap-2 items-center">
//     <span className="text-xs px-1.5 py-0.5 bg-primary-green rounded-sm text-white flex items-center gap-0.5">
//       {ratings.toFixed(1)} <StarIcon sx={{ fontSize: "14px" }} />
//     </span>
//     <span>({numOfReviews})</span>
//   </span>

//   {/* Price */}
//   <div className="flex items-center gap-1.5 text-md font-medium">
//     <span>₹{price.toLocaleString()}</span>
//     <span className="text-gray-500 line-through text-xs">
//       ₹{cuttedPrice.toLocaleString()}
//     </span>
//     <span className="text-xs text-primary-green">
//       {getDiscount(price, cuttedPrice)}% off
//     </span>
//   </div>

//   {/* Shop Now Button */}
//   <div className="flex items-center gap-3">
//     <Link to={`/products/${_id}`}>
//       <button className="cursor-pointer bg-primary-pink text-white text-sm py-1 px-2 rounded-sm">
//         Shop now
//       </button>
//     </Link>
//   </div>
// </div>

// {/* Wishlist Badge */ }
// <span
//   onClick={addToWishlistHandler}
//   className={`${itemInWishlist ? "text-red-500" : "hover:text-red-500 text-gray-300"
//     } absolute top-6 right-6 cursor-pointer`}
// >
//   <FavoriteIcon sx={{ fontSize: "18px" }} />
// </span>
//     </div >
//   )
// }

export default Product;
