import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Pagination from "@mui/material/Pagination";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Slider from "@mui/material/Slider";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../Layouts/Loader";
import Product from "./Product";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarIcon from "@mui/icons-material/Star";
import MetaData from "../Layouts/MetaData";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import './products.css'
import {
   allProductsFail,
   allProductsRequest,
   allProductsSuccess,
   clearErrors,
} from "../../store/productSlice";
import publicCommunication from "../../service/publicCommunication";
import { useCurrencyConverter } from "../../utils/useCurrencyConverter";
import CancelIcon from '@mui/icons-material/Cancel';
import TuneIcon from '@mui/icons-material/Tune';

const Products = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const [searchParams] = useSearchParams();
   const location = useLocation();
   const { convertPrice, symbol } = useCurrencyConverter();
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [categories, setCategories] = useState([]);
   const [keyword, setKeyword] = useState("");
   const [category, setCategory] = useState("");
   const [subcategory, setSubcategory] = useState("");
   const [price, setPrice] = useState([0, 200000]);
   const [ratings, setRatings] = useState("");
   // const [category, setCategory] = useState(
   //   location.search ? location.search.split("=")[1] : ""
   // );

   // pagination
   const [currentPage, setCurrentPage] = useState(1);

   // filter toggles
   const [categoryToggle, setCategoryToggle] = useState(true);
   const [ratingsToggle, setRatingsToggle] = useState(true);
   const [isFilterOpen, setIsFilterOpen] = useState(false);

   const {
      products,
      loading,
      error,
      productsCount,
      totalPages,
      resultPerPage,
      filteredProductsCount,
   } = useSelector((state) => state?.products);

   const getCategoryAndSubCategories = async (controller) => {
      try {
         const serverResponse =
            await publicCommunication?.fetchCategoryAndSubCategories(
               controller
            );
         if (serverResponse?.data?.success === true) {
            setCategories(serverResponse?.data?.data);
         } else {
            setCategories([]);
         }
      } catch (error) {
         enqueueSnackbar(
            error?.message ?? "Fetching categories and sub-categories Failed!",
            {
               variant: "error",
            }
         );
      }
   };

   const handlePageChange = (event, value) => {
      setCurrentPage(value); // Update the current page state
   };

   const clearFilters = () => {
      navigate(`/products`);
   };

   // Get All Products --- Filter/Search/Sort
   const getProducts = async (
      keyword = "",
      price = [0, 200000],
      ratings = 0,
      currentPage = 1,
      category,
      subcategory,
      controller
   ) => {
      try {
         dispatch(allProductsRequest());
         const serverResponse = await publicCommunication?.getAllProducts(
            keyword,
            price,
            ratings,
            currentPage,
            category,
            subcategory,
            controller
         );
         if (serverResponse?.data?.success === true) {
            dispatch(allProductsSuccess(serverResponse?.data));
         }
      } catch (error) {
         if (error.name === "CanceledError") {
            //do nothing
         } else {
            dispatch(allProductsFail("Something went wrong!"));
         }
      }
   };

   function handleNavigation(queryParams) {
      const searchParams = new URLSearchParams(location.search);
      if (queryParams.category) {
         searchParams.set("subcategory", "");
         searchParams.set("category", queryParams.category);
      }
      if (queryParams.subcategory) {
         searchParams.set("subcategory", queryParams.subcategory);
      }
      if (queryParams.ratings) {
         searchParams.set("ratings", queryParams.ratings);
      }
      if (queryParams.price) {
         searchParams.set("price", queryParams.price);
      }
      navigate(`/products?${searchParams.toString()}`);
   }

   useEffect(() => {
      const controller = new AbortController();
      if (error) {
         enqueueSnackbar(error, { variant: "error" });
         dispatch(clearErrors());
      }
      getProducts(
         keyword,
         price,
         ratings,
         currentPage,
         category,
         subcategory,
         controller
      );
      return () => {
         controller.abort();
      };
   }, [keyword, price, ratings, currentPage, category, subcategory]);

   useEffect(() => {
      const controller = new AbortController();
      getCategoryAndSubCategories(controller);
      return () => {
         controller.abort();
      };
   }, []);

   useEffect(() => {
      const searchParams = new URLSearchParams(location.search);

      setKeyword(searchParams.get("search") || "");
      setCategory(searchParams.get("category") || "");
      setSubcategory(searchParams.get("subcategory") || "");
      setRatings(searchParams.get("ratings") || "");
   }, [location.search]);

   useEffect(() => {
      const handleResize = () => {
        if(window.innerWidth >= 1000){
         setIsFilterOpen(true);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

   return (
      <>
         <MetaData title="All Products | Mahahandloom" />
         <section className="w-full overflow-hidden bg-primary-beige dark:bg-gray-950 flex justify-center items-center">
            <main className="w-full pt-5 position-relative">
               {/* <!-- row --> */}
               <button onClick={()=>setIsFilterOpen(!isFilterOpen)} className='filterBtn'><TuneIcon/> Filter </button>
               <div className="MainWrapper">
                  {/* <!-- sidebar column  --> */}
                  <div className={!isFilterOpen?'hidden' : 'block'}>
                     {/* <!-- nav tiles --> */}
                     <div onClick={()=>setIsFilterOpen(!isFilterOpen)} className="bg-white dark:bg-primary-black dark:text-white dark:border  dark:border-gray-600">
                        <CancelIcon/>
                     </div>
                     <div className="flex flex-col bg-white dark:bg-primary-black dark:border dark:border-gray-600 dark:text-white rounded-sm shadow h-[60vh] overflow-y-auto ">
                        {/* <!-- filters header --> */}
                        <div className="flex items-center justify-between gap-5 px-4 py-2 border-b dark:border-gray-600">
                           <p className="text-lg font-medium">Filters</p>
                           <span
                              className="uppercase text-primary-blue dark:text-blue-600 text-xs cursor-pointer font-medium"
                              onClick={() => clearFilters()}
                           >
                              clear all
                           </span>
                        </div>

                        <div className="flex flex-col gap-2 py-3 text-sm overflow-y-auto overflow-x-hidden">
                           {/* price slider filter */}
                           <div className="flex flex-col gap-2 border-b px-4 dark:border-gray-600">
                              <span className="font-medium text-xs">PRICE</span>

                              <Slider
                                 value={price}
                                 onChange={(e) => setPrice(e?.target?.value)}
                                 valueLabelDisplay="auto"
                                 getAriaLabel={() => "Price range slider"}
                                 min={0}
                                 max={200000}
                              />

                              <div className="flex gap-3 items-center justify-between mb-2 min-w-full">
                                 <span className="flex-1 border px-4 py-1 rounded-sm text-gray-800 bg-gray-50">
                                    {`${symbol()} ${convertPrice(price?.[0])}`}
                                 </span>
                                 <span className="font-medium text-gray-400">
                                    to
                                 </span>
                                 <span className="flex-1 border px-4 py-1 rounded-sm text-gray-800 bg-gray-50">
                                    {`${symbol()} ${convertPrice(price?.[1])}`}
                                 </span>
                              </div>
                           </div>
                           {/* price slider filter */}

                           {/* category filter */}
                           <div className="flex flex-col border-b px-4 dark:border-gray-600">
                              <div
                                 className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                                 onClick={() =>
                                    setCategoryToggle(!categoryToggle)
                                 }
                              >
                                 <p className="font-medium text-xs uppercase">
                                    Category
                                 </p>
                                 {categoryToggle ? (
                                    <ExpandLessIcon sx={{ fontSize: "20px" }} />
                                 ) : (
                                    <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                                 )}
                              </div>
                              {categoryToggle && (
                                 <ul className="">
                                    {categories?.map((data, index) => (
                                       <>
                                          {/* category list  */}
                                          <li
                                             key={index}
                                             className={`${
                                              data?.category?._id === category &&
                                                "bg-blue-100 text-blue-800 dark:text-blue-800"
                                             } cursor-pointer capitalize py-4 font-medium text-sm dark:text-primary-beige text-primary-black relative dark:border-gray-600 border-b`}
                                             onClick={() => {
                                                selectedCategory === index
                                                   ? setSelectedCategory(null)
                                                   : setSelectedCategory(index);

                                                handleNavigation({
                                                   category:
                                                      data?.category?._id,
                                                });
                                             }}
                                          >
                                             <span
                                                className="flex items-center justify-between "
                                                to={data?.link}
                                             >
                                                {data?.category?.name}
                                                {data?.subcategories?.length >
                                                0 ? (
                                                   index ===
                                                   selectedCategory ? (
                                                      <KeyboardArrowUpIcon
                                                         fontSize="inherit"
                                                         className="text-primary-black dark:text-primary-beige"
                                                      />
                                                   ) : (
                                                      <KeyboardArrowDownIcon
                                                         fontSize="inherit"
                                                         className="text-primary-black dark:text-primary-beige"
                                                      />
                                                   )
                                                ) : (
                                                   <span></span>
                                                )}
                                             </span>
                                          </li>
                                          {/* sub categories list  */}
                                          {index === selectedCategory && (
                                             <FormControl>
                                                <RadioGroup
                                                   aria-labelledby="category-radio-buttons-group"
                                                   name="category-radio-buttons"
                                                   value={subcategory}
                                                   onClick={(e) => {
                                                      handleNavigation({
                                                         subcategory:
                                                            e.target.value,
                                                      });
                                                   }}
                                                >
                                                   {data?.subcategories?.map(
                                                      (el, i) => (
                                                         <>
                                                            <FormControlLabel
                                                               className="capitalize"
                                                               value={el?._id}
                                                               control={
                                                                  <Radio size="small" />
                                                               }
                                                               label={
                                                                  <span
                                                                     className="text-sm font-medium"
                                                                     key={i}
                                                                  >
                                                                     {el?.name}
                                                                  </span>
                                                               }
                                                            />
                                                         </>
                                                      )
                                                   )}
                                                </RadioGroup>
                                             </FormControl>
                                          )}
                                          {/* sub categories list  */}
                                       </>
                                    ))}
                                 </ul>
                              )}
                           </div>
                           {/* category filter */}

                           {/* ratings filter */}
                           <div className="flex flex-col border-b px-4 dark:border-gray-600">
                              <div
                                 className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                                 onClick={() =>
                                    setRatingsToggle(!ratingsToggle)
                                 }
                              >
                                 <p className="font-medium text-xs uppercase">
                                    ratings
                                 </p>
                                 {ratingsToggle ? (
                                    <ExpandLessIcon sx={{ fontSize: "20px" }} />
                                 ) : (
                                    <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                                 )}
                              </div>

                              {ratingsToggle && (
                                 <div className="flex flex-col pb-1">
                                    <FormControl>
                                       <RadioGroup
                                          aria-labelledby="ratings-radio-buttons-group"
                                          onChange={(e) =>
                                             handleNavigation({
                                                ratings: e.target.value,
                                             })
                                          }
                                          value={ratings}
                                          name="ratings-radio-buttons"
                                       >
                                          {[4, 3, 2, 1].map((el, i) => (
                                             <FormControlLabel
                                                value={el}
                                                key={i}
                                                control={<Radio size="small" />}
                                                label={
                                                   <span className="flex items-center text-sm">
                                                      {el}
                                                      <StarIcon
                                                         sx={{
                                                            fontSize: "12px",
                                                            mr: 0.5,
                                                         }}
                                                      />{" "}
                                                      & above
                                                   </span>
                                                }
                                             />
                                          ))}
                                       </RadioGroup>
                                    </FormControl>
                                 </div>
                              )}
                           </div>
                           {/* ratings filter */}
                        </div>
                     </div>
                     {/* <!-- nav tiles --> */}
                  </div>
                  {/* <!-- sidebar column  --> */}

                  {/* <!-- product column --> */}
                  {loading ? (
                     <div className="flex-1">
                        <Loader />
                     </div>
                  ) : (
                     <div className="w-full">
                        {products?.length > 0 ? (
                           <div className="w-full relative">
                              <div className="productsWrapper">
                                 {products?.map((data, i) => {
                                    return (
                                       <Product
                                          product={data}
                                          _id={data?._id}
                                          name={data?.name}
                                          images={data?.brand?.logo?.url}
                                          ratings={data?.ratings}
                                          numOfReviews={data?.numOfReviews}
                                          price={data?.price}
                                          cuttedPrice={data?.cuttedPrice}
                                       />
                                    );
                                 })}
                              </div>
                           </div>
                        ) : (
                           <div className="flex flex-col items-center justify-center gap-3 dark:bg-primary-black bg-primary-beige shadow-sm rounded-sm p-6 sm:p-16">
                              <img
                                 draggable="false"
                                 className="w-1/2 h-44 object-contain"
                                 src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png"
                                 alt="Search Not Found"
                              />
                              <h1 className="text-2xl font-medium text-gray-900 dark:text-primary-beige">
                                 Sorry, no results found!
                              </h1>
                              <p className="text-xl text-center text-primary-grey">
                                 Please check the spelling or try searching for
                                 something else
                              </p>
                           </div>
                        )}
                     </div>
                  )}

                  {/* <!-- product column --> */}
               </div>
               {/* <!-- row --> */}
            </main>
         </section>
         <div className="flex items-center justify-center py-5 dark:bg-gray-950 dark:text-white">
            <Pagination
               count={totalPages }
               color="primary"
               page={currentPage}
               onChange={handlePageChange}
               className="dark:bg-gray-100 p-2 rounded-lg"
            />
         </div>
      </>
   );
};

export default Products;
