import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MetaData from "../Layouts/MetaData";
import CartItem from "./CartItem";
import EmptyCart from "./EmptyCart";
import PriceSidebar from "./PriceSidebar";
import SaveForLaterItem from "./SaveForLaterItem";
import Loader from "../Layouts/Loader";

const Cart = () => {
  const navigate = useNavigate();
  const { cartItems, loading } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { saveForLaterItems } = useSelector((state) => state.saveForLater);

  const placeOrderHandler = () => {
    navigate("/login?redirect=shipping");
  };

  return user && loading ? (
    <Loader />
  ) : (
    <>
      <MetaData title="Shopping Cart | Mahahandloom" />
      <main className="w-full pt-7 bg-primary-beige dark:bg-primary-black">
        {/* <!-- row --> */}
        <div className="flex flex-col lg:flex-row gap-3.5 w-full lg:w-11/12 mt-0 lg:mt-4 m-auto bg-primary-beige dark:bg-primary-black">
          {/* <!-- cart column --> */}
          <div className="flex-1">
            {/* <!-- cart items container --> */}
            <div className="flex flex-col shadow bg-white dark:bg-primary-black dark:border dark:border-gray-600">
              <span className="font-medium text-lg px-2 lg:px-8 py-4 border-b dark:border-gray-600 dark:text-primary-beige">
                My Cart ({cartItems?.length || 0})
              </span>

              {cartItems?.length > 0 ? (
                cartItems?.map((data, index) => (
                  <CartItem
                    product={data}
                    id={data?._id}
                    seller={data?.brand?.name ?? "Mahahandloom"}
                    image={data?.brand?.logo?.url}
                    stock={data?.stock}
                    quantity={data?.quantity ?? 1}
                    inCart={true}
                    name={data?.name}
                    price={data?.price}
                    cuttedPrice={data?.cuttedPrice}
                  />
                ))
              ) : (
                <EmptyCart />
              )}

              {/* <!-- place order btn --> */}
              {cartItems?.length > 0 && (
                <div className="flex justify-end">
                  <button
                    onClick={placeOrderHandler}
                    disabled={cartItems?.length < 1 ? true : false}
                    className={`${
                      cartItems?.length < 1
                        ? "bg-primary-grey cursor-not-allowed"
                        : "bg-primary-pink"
                    } w-full lg:w-1/3 mx-2 lg:mx-6 my-4 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm`}
                  >
                    PLACE ORDER
                  </button>
                </div>
              )}

              {/* <!-- place order btn --> */}
            </div>
            {/* <!-- saved for later items container --> */}
            <div className="flex flex-col mt-5 shadow bg-white dark:bg-primary-black mb-5 dark:border dark:border-gray-600">
              <span className="font-medium text-lg px-2 sm:px-8 py-4 border-b dark:border-gray-600 dark:text-primary-beige">
                Saved For Later ({saveForLaterItems?.length || 0})
              </span>
              {saveForLaterItems &&
                saveForLaterItems.map((item) => (
                  <SaveForLaterItem
                    product={item}
                    _id={item?._id}
                    name={item?.name}
                    price={item?.price}
                    cuttedPrice={item?.cuttedPrice}
                    brand={item?.brand}
                    quantity={item?.quantity || 1}
                  />
                ))}
            </div>
            {/* <!-- cart items container --> */}
          </div>
          {/* <!-- cart column --> */}
          {cartItems?.length > 0 && (
            <PriceSidebar cartItems={cartItems} isCouponApply={true} />
          )}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  );
};

export default Cart;
