import React from "react";
import chatbot from "../assets/images/chat-bot.png";

const ChatBot = () => {
  return (
    <div className="w-[60px] h-[60px] fixed right-[3%] bottom-[4%] dark:bg-primary-black dark:border-gray-600 bg-white p-2 rounded-full cursor-pointer z-50 border">
      <img
        src={chatbot}
        className="h-full w-full object-contain"
        draggable={false}
        alt="chat bot"
      />
    </div>
  );
};

export default ChatBot;
