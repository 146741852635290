import { useEffect, useRef, useState } from "react";
import "./home.css";
import { useSnackbar } from "notistack";
import MetaData from "../Layouts/MetaData";
import topPattern from "../../assets/images/home/header-pattern.png";
import SidePattern from "./SidePattern";
import pinkFlagStrip from "../../assets/images/home/pink-flag-strip.png";
import flowerLineLtr from "../../assets/images/home/flower-line-ltr.png";
import flowerLineRtl from "../../assets/images/home/flower-line-rtl.png";
import StarIcon from "@mui/icons-material/Star";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Link, Navigate, useNavigate } from "react-router-dom";
import btnCircle from "../../assets/images/home/btn-circle.png";
import aboutImg from "../../assets/images/home/about.png";
import Slider from "react-slick";
import Avatar from "@mui/material/Avatar";
import publicCommunication from "../../service/publicCommunication";

// shop by craft images
import craftBanarasi from "../../assets/images/home/mhd/craft/banarasi.jpeg";
import craftPochampally from "../../assets/images/home/mhd/craft/pochampally.jpeg";
import craftBaluchari from "../../assets/images/home/mhd/craft/baluchari.jpeg";
import craftChattisgarhSlik from "../../assets/images/home/mhd/craft/chattisgarh-slik.jpeg";
import LoadingScaletonCard from "../LoadingScaletonCard";
import { useCurrencyConverter } from "../../utils/useCurrencyConverter";

const Home = () => {
   const { enqueueSnackbar } = useSnackbar();
   const [testimonial, setTestimonial] = useState([]);
   const [shopByCategory, setShopByCategory] = useState([]);
   const [shopByCraft, setShopByCraft] = useState([]);
   const [uniqueProducts, setUniqueProducts] = useState([]);
   const [homeTextile, setHomeTextile] = useState([]);
   const [mensWear, setMensWear] = useState([]);
   const [shopBySarees, setShopBySarees] = useState([]);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const { convertPrice, symbol } = useCurrencyConverter();
   // slider reference
   const textSliderRef = useRef(null);
   const imageSliderRef = useRef(null);

   const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      beforeChange: (current, next) => {
         // Sync both sliders
         if (textSliderRef.current && imageSliderRef.current) {
            textSliderRef.current.slickGoTo(next);
            imageSliderRef.current.slickGoTo(next);
         }
      },
   };

   const heroSliderData = [
      {
         img: craftBanarasi,
         text: "Banarasi",
         tagline1: "unleash your inner diva",
         tagline2: "with our saree collections",
      },
      {
         img: craftPochampally,
         text: "Pochampally Ikat",
         tagline1: "add Pochampally Ikat",
         tagline2: "in your saree collections",
      },
      {
         img: craftChattisgarhSlik,
         text: "Premium Chattisgarh Silk",
         tagline1: "add Chattisgarh Silk",
         tagline2: "to unleash your inner diva",
      },
      {
         img: craftBaluchari,
         text: "Baluchari",
         tagline1: "add Handwoven Baluchari",
         tagline2: "in your saree collections",
      },
   ];

   // social media updates
   const instagramVideoArray = [
      "CvzJH7UIpC7",
      "CyaXkzTISlI",
      "CsdEC0Et_Ys",
      "CgmIPbjKOQE",
   ];

   const testimonialSettings = {
      autoplay: true,
      autoplaySpeed: 2000,
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024, // Tablet and smaller screens
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               dots: true,
            },
         },
         {
            breakpoint: 768, // Mobile screens
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               dots: true,
            },
         },
      ],
   };

   // function to fetch all testimonials
   const getTestimonials = async (controller) => {
      try {
         setLoading(true);
         const serverResponse = await publicCommunication?.fetchTestimonials(
            controller
         );
         if (serverResponse?.data?.success === true) {
            setTestimonial(serverResponse?.data?.testimonials);
         } else {
            setTestimonial([]);
         }
      } catch (error) {
         enqueueSnackbar(error?.message ?? "Fetching testimonial Failed!", {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };

   // fetch all categories
   const allCategory = async () => {
      try {
         setLoading(true);
         const response = await publicCommunication?.getAllCategory();
         if (response?.data?.success) {
            setShopByCategory(response?.data?.category);
         } else {
            enqueueSnackbar("Failed to fetch categories.", {
               variant: "error",
            });
         }
      } catch (err) {
         enqueueSnackbar("Error fetching categories: " + err.message, {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };

   // get shop by craft
   const allSaree = async () => {
      try {
         setLoading(true);
         let category = "677f5411dfc14c680d52db61";
         let subCategory = "677f5cd7a55fc30bd525571d";
         const response = await publicCommunication?.getAllSarees(
            category,
            subCategory
         );
         if (response?.data?.success) {
            setShopByCraft(response?.data?.products);
         } else {
            enqueueSnackbar("Failed to fetch craft.", { variant: "error" });
         }
      } catch (err) {
         enqueueSnackbar("Error fetching craft: " + err.message, {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };

   // fetch accessories
   const getAccessories = async () => {
      try {
         setLoading(true);
         let category = "677f5583dfc14c680d52dbaf";
         let subCategory = "677f5cd7a55fc30bd525571d";

         const response = await publicCommunication?.getAllSarees(
            category,
            subCategory
         );
         if (response?.data?.success) {
            setUniqueProducts(response?.data?.products);
         } else {
            enqueueSnackbar("Failed to fetch accessories.", {
               variant: "error",
            });
         }
      } catch (err) {
         enqueueSnackbar("Error fetching accessories: " + err.message, {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };

   // fetch mens wear
   const getMensWare = async () => {
      try {
         setLoading(true);
         let category = "677f5334dfc14c680d52db4c";

         const response = await publicCommunication?.getProductByCategory(
            category
         );
         if (response?.data?.success) {
            setMensWear(response?.data?.products);
         } else {
            enqueueSnackbar("Failed to fetch accessories.", {
               variant: "error",
            });
         }
      } catch (err) {
         enqueueSnackbar("Error fetching accessories: " + err.message, {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };

   // fetch home textiles
   const getHomeTextiles = async () => {
      try {
         setLoading(true);
         let category = "677f5562dfc14c680d52dba3";

         const response = await publicCommunication?.getProductByCategory(
            category
         );
         if (response?.data?.success) {
            setHomeTextile(response?.data?.products);
         } else {
            enqueueSnackbar("Failed to fetch accessories.", {
               variant: "error",
            });
         }
      } catch (err) {
         enqueueSnackbar("Error fetching accessories: " + err.message, {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };
   // fetch sarees
   const getSarees = async () => {
      try {
         setLoading(true);
         let category = "677f5411dfc14c680d52db61";

         const response = await publicCommunication?.getProductByCategory(
            category
         );
         if (response?.data?.success) {
            setShopBySarees(response?.data?.products);
         } else {
            enqueueSnackbar("Failed to fetch accessories.", {
               variant: "error",
            });
         }
      } catch (err) {
         enqueueSnackbar("Error fetching accessories: " + err.message, {
            variant: "error",
         });
      } finally {
         setLoading(false);
      }
   };

   // increase visitors
   const increaseVisitors = async () => {
      try {
         await publicCommunication?.increaseVisitors();
      } catch (error) {
         enqueueSnackbar("Error fetching accessories: " + error.message, {
            variant: "error",
         });
      }
   };

   // shop by category
   const handleShopByCategory = (categoryId) => {
      try {
         navigate(`/products?category=${categoryId}`);
      } catch (err) {
         enqueueSnackbar("Error fetching accessories: " + err.message, {
            variant: "error",
         });
      }
   };

   useEffect(() => {
      const controller = new AbortController();
      getTestimonials(controller);
      allCategory();
      allSaree();
      getAccessories();
      getHomeTextiles();
      getMensWare();
      getSarees();
      increaseVisitors();
      return () => {
         controller.abort();
      };
   }, []);

   return (
      <>
         <MetaData title="Mahahandloom | Home" />
         <main className="gap-3 bg-primary-beige dark:bg-primary-black">
            {/* <Banner /> */}
            {/* <Navmenu /> */}

            {/*  ************ hero section ************   */}
            <section className="pattern-section relative w-full bg-primary-beige dark:bg-primary-black flex justify-center items-center">
               <div className="hero-slider-wrapper relative w-full h-full grid grid-cols-1 gap-10 sm:grid-cols-2 py-3 sm:gap-1 px-7 lg:px-10 xl:px-14">
                  <SidePattern
                     className={"absolute left-0 dark:hidden"}
                     side="left"
                  />
                  {/* right flower pattern  */}
                  <SidePattern
                     className={"absolute right-0 dark:hidden"}
                     side="right"
                  />
                  <div className="hero-slider-content self-center grid grid-cols-1 gap-5">
                     <Slider {...settings} ref={textSliderRef}>
                        {heroSliderData?.map((data, i) => (
                           <h1
                              key={i}
                              className="capitalize text-center w-[100%] text-primary-pink dark:text-yellow-100 font-['Branch'] text-[28px] mb-0 sm:text-4xl md:text-5xl  lg:text-6xl xl:text-7xxl"
                           >
                              {data?.tagline1} <br />
                              <span className="capitalize w-[100%] text-center text-primary-blue dark:text-primary-pink font-['Branch'] text-[28px] mb-0 sm:text-4xl md:text-5xl  lg:text-6xl xl:text-7xxl">
                                 {" "}
                                 {data?.tagline2}
                              </span>
                           </h1>
                        ))}
                     </Slider>

                     <div className="text-center">
                        <Link to={"/products"}>
                           <button
                              type="button"
                              className="btn p-3 text-primary-black dark:text-primary-beige capitalize text-1xl sm:text-2xl relative cursor-pointer"
                           >
                              Explore now
                              <img
                                 src={btnCircle}
                                 className="h-full w-full object-contain absolute top-0 left-0 scale-[1.3]"
                                 draggable={false}
                                 alt="explore mahahandloom"
                              />
                           </button>
                        </Link>
                     </div>
                  </div>
                  <div className="hero-slider-img flex items-center justify-center">
                     <div
                        className="relative w-8/12
              before:content-[''] before:absolute before:top-0 before:left-0 before:border-[3px] before:border-primary-pink before:w-full before:h-full before:translate-x-[-25px] before:translate-y-3 before:rounded-tr-full before:rounded-tl-full
              "
                     >
                        <Slider {...settings} ref={imageSliderRef}>
                           {heroSliderData?.map((img, i) => (
                              <img
                                 key={i}
                                 draggable="false"
                                 className="h-full w-full object-contain shadow-2xl rounded-tr-full rounded-tl-full"
                                 src={img?.img}
                                 alt={img?.text}
                              />
                           ))}
                        </Slider>
                     </div>
                  </div>
                  <div className="top-design-pattern absolute left-0 top-0">
                     <img
                        src={topPattern}
                        className="h-full w-full object-contain"
                        draggable={false}
                        alt=""
                     />
                  </div>
               </div>
            </section>
            {/*  ************ shop by categories section ************   */}
            <section className="pattern-section w-full overflow-hidden bg-primary-beige dark:bg-primary-black">
               <div className="relative">
                  <img
                     src={pinkFlagStrip}
                     className="h-full w-full object-contain"
                     draggable={false}
                     alt=""
                  />
                  <div className="flex items-center justify-center flex-wrap gap-20 sm:gap-28 py-10 px-6 sm:px-14 sm:py-14 ">
                     <div className="">
                        <h5 className="uppercase text-center font-bold text-[26px] sm:text-3xl text-primary-pink dark:text-primary-beige font-['Branch']">
                           Largest selection
                        </h5>
                        <p className="text-primary-pink  dark:text-primary-beige text-base text-[12px] sm:text-[16px] capitalize text-center font-medium">
                           of sarees with unique designs
                        </p>
                     </div>
                     <div className="">
                        <h5 className="uppercase text-center font-bold text-[26px] sm:text-3xl text-primary-pink  dark:text-primary-beige font-['Branch']">
                           Explore The
                        </h5>
                        <p className="text-primary-pink  dark:text-primary-beige text-base text-[12px] sm:text-[16px] capitalize text-center font-medium">
                           varieties of sarees & clothes
                        </p>
                     </div>
                     <div className="">
                        <h5 className="uppercase text-center font-bold text-[26px] sm:text-3xl text-primary-pink  dark:text-primary-beige font-['Branch']">
                           Free shipping
                        </h5>
                        <p className="text-primary-pink  dark:text-primary-beige text-base text-[12px] sm:text-[16px] capitalize text-center font-medium">
                           all over india
                        </p>
                     </div>
                  </div>
                  <img
                     src={pinkFlagStrip}
                     className="h-full w-full object-contain rotate-180"
                     draggable={false}
                     alt=""
                  />
               </div>
               <div className="py-5">
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     shop by categories
                  </h2>
                  <div className="shopByCategorieWrapper">
                     {shopByCategory?.length > 0 &&
                        !loading &&
                        shopByCategory?.map((category, index) => {
                           return (
                              <div key={index}>
                                 <div>
                                    <img
                                       src={category?.image[0]?.url}
                                       className="h-[100%] w-[100%] object-cover object-top hover:shadow-lg cursor-pointer"
                                       draggable={false}
                                       alt={category?.name}
                                       onClick={() =>
                                          handleShopByCategory(category?._id)
                                       }
                                    />
                                 </div>
                                 <p className="text-[18px] sm:text-2xl p-3 uppercase text-center mt-auto text-black  dark:text-primary-beige font-medium">
                                    {category?.name}
                                 </p>
                              </div>
                           );
                        })}
                     {(!shopByCategory?.length || loading) && (
                        <LoadingScaletonCard isLoading={loading} />
                     )}
                  </div>
               </div>
            </section>
            {/*  ************ shop by craft section ************   */}
            <section className="pattern-section w-full bg-primary-beige dark:bg-primary-black relative">
               <SidePattern
                  className={"absolute left-0 dark:hidden"}
                  side="left"
               />
               {/* right flower pattern  */}
               <SidePattern
                  className={"absolute right-0 dark:hidden"}
                  side="right"
               />
               <img
                  src={flowerLineLtr}
                  className="absolute top-0 translate-y-[-50%] z-10 h-auto w-full object-contain"
                  draggable={false}
                  alt=""
               />
               <div className="py-10">
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     shop by craft
                  </h2>
                  <div className="shopByCraftWrapper">
                     {shopByCraft?.length > 0 &&
                        !loading &&
                        shopByCraft?.slice(0, 4)?.map((product, index) => {
                           return (
                              <div
                                 className="w-full shadow-2xl rounded-t-full bg-white dark:bg-primary-darkBlue border border-primary-pink"
                                 key={index}
                              >
                                 <div>
                                    <img
                                       src={product?.images[0]?.url}
                                       className="h-full w-full object-cover object-center cursor-pointer rounded-t-full border border-primary-pink"
                                       draggable={false}
                                       alt={product?.text}
                                       onClick={() =>
                                          handleShopByCategory(product?._id)
                                       }
                                    />
                                 </div>
                                 <p className="text-2xl p-3 text-center mt-auto text-black dark:text-primary-beige font-medium">
                                    {product?.name}
                                 </p>
                              </div>
                           );
                        })}
                  </div>
                  {(!shopByCraft?.length || loading) && (
                     <LoadingScaletonCard isLoading={loading} />
                  )}
               </div>
            </section>
            {/*  ************ unique product section ************   */}
            <section className="pattern-section w-full bg-primary-beige dark:bg-primary-black relative">
               <SidePattern
                  className={"absolute left-0 dark:hidden"}
                  side="left"
               />
               {/* right flower pattern  */}
               <SidePattern
                  className={"absolute right-0 dark:hidden"}
                  side="right"
               />
               <img
                  src={flowerLineRtl}
                  className="absolute top-0 translate-y-[-25%] z-10 h-auto w-full object-contain"
                  draggable={false}
                  alt=""
               />
               <div className="py-24">
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     Unique Products
                  </h2>
                  <div className="shopByCraftWrapper">
                     {uniqueProducts?.length > 0 &&
                        !loading &&
                        uniqueProducts?.slice(0, 4)?.map((uniquePro, index) => {
                           return (
                              <div
                                 className=" w-full shadow-2xl rounded-t-full bg-white dark:bg-primary-darkBlue border border-primary-pink"
                                 key={index}
                              >
                                 <div>
                                    <img
                                       src={uniquePro?.brand?.logo?.url}
                                       className="h-full w-full object-cover rounded-t-full border border-primary-pink cursor-pointer"
                                       draggable={false}
                                       alt={uniquePro?.name}
                                       onClick={() =>
                                          handleShopByCategory(uniquePro?.category)
                                       }
                                    />
                                 </div>
                                 <p className="capitalize text-base text-center text-gray-700 dark:text-gray-400 font-medium mt-2">
                                    {uniquePro?.name}
                                 </p>
                                 <p className="text-xl text-center text-black dark:text-primary-beige font-medium mb-2">
                                    {`${symbol()}${convertPrice(
                                       uniquePro?.price
                                    )}`}
                                 </p>
                              </div>
                           );
                        })}
                  </div>
                  {(!uniqueProducts?.length || loading) && (
                     <LoadingScaletonCard isLoading={loading} />
                  )}
               </div>
            </section>
            {/*  ************ sarees section ************   */}
            <section className="pattern-section w-full bg-primary-beige dark:bg-primary-black relative">
               <SidePattern
                  className={"absolute left-0 dark:hidden"}
                  side="left"
               />
               {/* right flower pattern  */}
               <SidePattern
                  className={"absolute right-0 dark:hidden"}
                  side="right"
               />
               <img
                  src={flowerLineLtr}
                  className="absolute top-0 translate-y-[-50%] z-10 h-auto w-full object-contain"
                  draggable={false}
                  alt=""
               />
               <div className="py-10">
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     Sarees
                  </h2>
                  <div className="shopByCraftWrapper">
                     {shopBySarees?.length > 0 &&
                        !loading &&
                        shopBySarees?.slice(0, 4)?.map((saree, index) => {
                           return (
                              <div key={index}>
                                 <div>
                                    <img
                                       src={saree?.brand?.logo?.url}
                                       className="h-full w-full object-cover hover:shadow-lg cursor-pointer"
                                       draggable={false}
                                       alt={saree?.name}
                                       onClick={() =>
                                          handleShopByCategory(saree?.category)
                                       }
                                    />
                                 </div>
                                 <p className="capitalize text-lg text-center text-gray-700 dark:text-gray-400 font-medium mt-3">
                                    {saree?.name}
                                 </p>
                                 <p className="text-xl text-center text-black dark:text-primary-beige font-medium mb-3">
                                    {`${symbol()}${convertPrice(saree?.price)}`}
                                 </p>
                              </div>
                           );
                        })}
                  </div>
                  {(!shopBySarees?.length || loading) && (
                     <LoadingScaletonCard isLoading={loading} />
                  )}
               </div>
            </section>
            {/*  ************ mens wear section ************   */}
            <section className="pattern-section w-full bg-primary-beige dark:bg-primary-black relative">
               <SidePattern
                  className={"absolute left-0 dark:hidden"}
                  side="left"
               />
               {/* right flower pattern  */}
               <SidePattern
                  className={"absolute right-0 dark:hidden"}
                  side="right"
               />
               <img
                  src={flowerLineRtl}
                  className="absolute top-0 translate-y-[-40%] z-10 h-auto w-full object-contain"
                  draggable={false}
                  alt=""
               />
               <div className="py-20">
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     Mens Wear
                  </h2>
                  <div className="shopByCraftWrapper">
                     {mensWear?.length > 0 &&
                        !loading &&
                        mensWear?.slice(0, 4)?.map((mensWear, index) => {
                           return (
                              <div key={index}>
                                 <div>
                                    <img
                                       src={mensWear?.brand?.logo?.url}
                                       className="h-full w-full object-cover hover:shadow-lg cursor-pointer"
                                       draggable={false}
                                       alt={mensWear?.name}
                                       onClick={() =>
                                          handleShopByCategory(mensWear?.category)
                                       }
                                    />
                                 </div>
                                 <p className="capitalize text-lg text-center text-gray-700 dark:text-gray-400 font-medium mt-3">
                                    {mensWear?.name}
                                 </p>
                                 <p className="text-xl text-center text-black dark:text-primary-beige font-medium">
                                    {`${symbol()}${convertPrice(
                                       mensWear?.price
                                    )}`}
                                 </p>
                              </div>
                           );
                        })}
                  </div>
                  {(!mensWear?.length || loading) && (
                     <LoadingScaletonCard isLoading={loading} />
                  )}
               </div>
            </section>
            {/*  ************ home textile section ************   */}
            <section className="pattern-section w-full bg-primary-beige dark:bg-primary-black relative">
               <SidePattern
                  className={"absolute left-0 dark:hidden"}
                  side="left"
               />
               {/* right flower pattern  */}
               <SidePattern
                  className={"absolute right-0 dark:hidden"}
                  side="right"
               />
               <img
                  src={flowerLineLtr}
                  className="absolute top-0 translate-y-[-50%] z-10 h-auto w-full object-contain"
                  draggable={false}
                  alt=""
               />
               <div className="py-10">
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     Home Textile
                  </h2>
                  <div className="shopByCraftWrapper">
                     {homeTextile?.length > 0 &&
                        !loading &&
                        homeTextile?.slice(0, 4)?.map((textile, index) => {
                           return (
                              <div key={index}>
                                 <div>
                                    <img
                                       src={textile?.brand?.logo?.url}
                                       className="h-full w-full object-cover hover:shadow-lg cursor-pointer"
                                       draggable={false}
                                       alt={textile?.name}
                                       onClick={() =>
                                          handleShopByCategory(textile?.category)
                                       }
                                    />
                                 </div>
                                 <p className="capitalize text-lg text-center text-gray-700 dark:text-gray-400 font-medium mt-3">
                                    {textile?.name}
                                 </p>
                                 <p className="text-xl text-center text-black dark:text-primary-beige font-medium">
                                    {`${symbol()}${convertPrice(
                                       textile?.price
                                    )}`}
                                 </p>
                              </div>
                           );
                        })}
                  </div>
                  {(!homeTextile?.length || loading) && (
                     <LoadingScaletonCard isLoading={loading} />
                  )}
               </div>
            </section>
            {/*  ************ Social media updates ************   */}
            <section className="overflow-hidden pattern-section w-full bg-primary-beige relative">
               {/* about us  */}
               <div className="bg-[#c0107f22] dark:bg-primary-darkBlue p-10 gap-5 overflow-hidden grid grid-cols-1 lg:grid-cols-2 lg:p-20 ">
                  <div className=" p-0 lg:p-5">
                     <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                        About us
                     </h2>
                     <p className="text-gray-700 dark:text-gray-400 font-base text-[14px] sm:text-[18px] md:text-[20px] py-5 ">
                        Maha Handloom is a renowned clothing brand based in
                        Nagpur, established in 1971. We are celebrated for our
                        exquisite collection of authentic sarees, along with
                        enchanting clothing materials and a luxury tie brand
                     </p>
                     <Link to={"/about"}>
                        <button className="bg-primary-pink text-white p-2 rounded-md">
                           Read More
                        </button>
                     </Link>
                  </div>
                  <div className=" h-[15rem] sm:h-[22rem] lg:h-[25rem]">
                     <img
                        src={aboutImg}
                        draggable={false}
                        className="h-full w-full object-cover aspect-auto"
                        alt=""
                     />
                  </div>
               </div>
               {/* social media updates  */}
               <div className="dark:bg-primary-black relative">
                  <SidePattern
                     className={"absolute left-0 dark:hidden"}
                     side="left"
                  />
                  {/* right flower pattern  */}
                  <SidePattern
                     className={"absolute right-0 dark:hidden"}
                     side="right"
                  />
                  <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                     Social Media Updates
                  </h2>
                  <div className="shopByCraftWrapper">
                     {instagramVideoArray?.length > 0 &&
                        instagramVideoArray?.map((data, index) => {
                           return (
                              <div className="relative" key={index}>
                                 {/* <img
                      src={bannerImg1}
                      className="h-full w-full object-cover rounded-2xl"
                      draggable={false}
                      alt="mahandloom sarees"
                    /> */}
                                 <iframe
                                    src={`https://www.instagram.com/reel/${data}/embed`}
                                    title="instagram reel"
                                    frameborder="0"
                                    allowtransparency="true"
                                    allowfullscreen="true"
                                    scrolling="no"
                                    className="h-full w-full object-cover rounded-2xl overflow-hidden"
                                 ></iframe>
                                 <button className="group pointer-events-none absolute bottom-4 right-4 hover:bg-primary-pink transition-all rounded-full btn bg-white p-3 flex items-center justify-center">
                                    <PlayArrowIcon
                                       fontSize="small"
                                       className="text-black group-hover:text-white"
                                    />
                                 </button>
                              </div>
                           );
                        })}
                  </div>
               </div>
            </section>

            {/* Testimonial section  */}
            <section className="overflow-hidden p-10 w-full bg-primary-beige dark:bg-primary-black relative">
               <h2 className="font-['Branch'] capitalize mt-4 text-center text-[36px] sm:text-[46px] md:text-7xxl font-medium text-primary-pink">
                  Testimonials
               </h2>
               {testimonial?.length > 0 && !loading && (
                  <div className="testimonial-slider my-10">
                     <Slider {...testimonialSettings}>
                        {testimonial?.slice(0,10)?.map((data, i) => (
                           // testimonial card
                           <div
                              key={i}
                              class="bg-white dark:bg-primary-black dark:border-gray-600 dark:text-primary-beige border border-solid border-gray-300 flex justify-between flex-col rounded-xl p-6 transition-all duration-500  w-full mx-auto hover:border-primary-pink hover:shadow-sm"
                           >
                              <div class="">
                                 <div class="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500  ">
                                    <span className="text-3xl font-semibold text-primary-yellow p-0 m-0">
                                       <StarIcon fontSize="inherit" />
                                    </span>
                                    <span class="text-xl mt-1 font-semibold text-primary-black dark:text-primary-beige m-0 p-0">
                                       {data?.rating.toLocaleString()}
                                    </span>
                                 </div>
                                 <p class="text-base text-gray-600 dark:text-white leading-6  transition-all duration-500 pb-8 slide_active:text-gray-800">
                                    {data?.message ?? "--"}
                                 </p>
                              </div>
                              <div class="flex items-center gap-5 pt-5 border-t dark:border-gray-600 border-solid border-gray-200">
                                 <Avatar
                                    src={data?.userId?.avatar?.url}
                                    sx={{ width: 56, height: 56 }}
                                 />
                                 <div class="block">
                                    <h5 class="text-gray-900 capitalize dark:text-primary-beige font-medium transition-all duration-500  mb-1">
                                       {data?.userId?.name ?? "User"}
                                    </h5>
                                    <span class="text-sm leading-4 text-gray-500 dark:text-gray-400">
                                       {data?.userId?.email}
                                    </span>
                                 </div>
                              </div>
                           </div>
                        ))}
                     </Slider>
                  </div>
               )}
            </section>
            {(!testimonial?.length || loading) && (
               <LoadingScaletonCard isLoading={loading} />
            )}
         </main>
      </>
   );
};

export default Home;
