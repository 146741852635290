import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getRandomProducts } from "../../../utils/functions";
import { settings } from "../DealSlider/DealSlider";
import Product from "./Product";
import { useSnackbar } from "notistack";
import publicCommunication from "../../../service/publicCommunication";
import { useEffect, useState } from "react";

const ProductSlider = ({ title, tagline, category }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (category) {
        try {
          const response = await publicCommunication?.getProductByCategory(category);
          if (response?.data?.success) {
            setSimilarProducts(response?.data?.products);
          } else {
            enqueueSnackbar("Failed to fetch products.", { variant: "error" });
          }
        } catch (error) {
          enqueueSnackbar("Error fetching products.", { variant: "error" });
        }
      }
    };

    fetchProducts();
  }, [category, enqueueSnackbar]);

  // const { loading, products } = useSelector((state) => state.products);

  return (
    <section className="bg-primary-beige dark:bg-primary-black w-full shadow overflow-hidden">
      {/* <!-- header --> */}
      <div className="flex px-6 py-4 justify-between items-center border border-gray-300 dark:border-gray-600">
        <div className="title flex flex-col gap-0.5">
          <h1 className="text-xl font-medium dark:text-primary-beige">{title}</h1>
          <p className="text-sm text-gray-400">{tagline}</p>
        </div>
        <Link
          to={`/products?category=${category?._id}`}
          className="bg-primary-blue text-xs font-medium text-white px-5 py-2.5 rounded-sm shadow-lg uppercase"
        >
          view all
        </Link>
      </div>
      <Slider {...settings} className="flex items-center justify-between p-1">
        {similarProducts.length > 0 ? (
          similarProducts.map((product) => (
            <Product
              key={product?._id} // Make sure the product has a unique key like `_id`
              _id={product?._id}
              name={product?.name}
              images={product?.brand?.logo?.url}
              ratings={product?.ratings}
              numOfReviews={product?.numOfReviews}
              price={product?.price}
              cuttedPrice={product?.cuttedPrice}
            />
          ))
        ) : (
          <div>No products available</div>
        )}
      </Slider>
    </section>
  );
};

export default ProductSlider;
