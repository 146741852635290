import { useEffect, useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackdropLoader from "../Layouts/BackdropLoader";
import MetaData from "../Layouts/MetaData";
import FormSidebar from "./FormSidebar";
import publicCommunication from "../../service/publicCommunication";
import {
  clearErrors,
  registerFail,
  registerRequest,
  registerSuccess,
} from "../../store/userSlice";
import { setCookie } from "cookies-next";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );

  // set user details
  const [user, setUser] = useState({
    name: "",
    email: "",
    gender: "",
    password: "",
    cpassword: "",
  });
  // destructure user
  const { name, email, gender, password, cpassword } = user;

  const [avatar, setAvatar] = useState();
  const [avatarPreview, setAvatarPreview] = useState("preview.png");

  const [showPassword, setShowPassword] = useState({
    password: false,
    cpassword: false,
  });

  const handleTogglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      enqueueSnackbar("Password length must be at least 8 characters", {
        variant: "warning",
      });
      return;
    }

    if (password !== cpassword) {
      enqueueSnackbar("Password doesn't match", { variant: "error" });
      return;
    }

    if (!avatar) {
      enqueueSnackbar("Select Avatar", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("password", password);
    formData.append("avatar", avatar);

    try {
      dispatch(registerRequest());
      const serverResponse = await publicCommunication?.registerUser(formData);
      if (serverResponse?.data?.success === true) {
        // add user data in  the user state of redux
        dispatch(registerSuccess(serverResponse?.data?.user));
        // set token and user details in cookies
        setCookie(process.env.REACT_APP_TOKENNAME, serverResponse?.data?.token);
        setCookie(
          process.env.REACT_APP_USERDETAILS,
          serverResponse?.data?.user
        );
        enqueueSnackbar("Registration Successful", { variant: "success" });
        navigate("/");
      } else {
        dispatch(registerFail("Something went wrong!"));
        enqueueSnackbar("Something went wrong!", { variant: "warning" });
      }
    } catch (error) {
      dispatch(registerFail(error?.message));
      enqueueSnackbar(error?.message ?? "Registration Failed!", {
        variant: "error",
      });
    }
  };

  const handleDataChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate("/");
    }
  }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

  return (
    <>
      <MetaData title="Register | Mahahandloom" />

      {loading && <BackdropLoader />}
      <main className="w-full mt-5 sm:pt-7 sm:mt-0 bg-primary-beige dark:bg-primary-black pb-5 pattern-section">
        {/* <!-- row --> */}
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white dark:bg-gray-100 shadow-lg rounded-lg overflow-hidden">
          <FormSidebar
            title="Create Your Account"
            tag="Sign up with your mobile number to explore exclusive deals and offers"
          />

          {/* <!-- signup column --> */}
          <div className="flex-1 overflow-hidden">
            {/* <!-- personal info procedure container --> */}
            <form
              onSubmit={handleRegister}
              encType="multipart/form-data"
              className="p-5 sm:p-10"
            >
              <div className="flex flex-col gap-4 items-start">
                {/* <!-- input container column --> */}
                <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                  <TextField
                    fullWidth
                    id="full-name"
                    label="Full Name"
                    name="name"
                    value={name}
                    onChange={handleDataChange}
                    required
                  />
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                {/* <!-- input container column --> */}

                {/* <!-- gender input --> */}
                <div className="flex gap-4 items-center">
                  <h2 className="text-md">Your Gender :</h2>
                  <div className="flex items-center gap-6" id="radioInput">
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        name="gender"
                        value="male"
                        onChange={handleDataChange}
                        control={<Radio required />}
                        label="Male"
                      />
                      <FormControlLabel
                        name="gender"
                        value="female"
                        onChange={handleDataChange}
                        control={<Radio required />}
                        label="Female"
                      />
                    </RadioGroup>
                  </div>
                </div>
                {/* <!-- gender input --> */}

                {/* <!-- input container column --> */}
                <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                  <TextField
                    id="password"
                    label="Password"
                    type={showPassword.password ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleDataChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleTogglePasswordVisibility("password")
                            }
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showPassword.password ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="confirm-password"
                    label="Confirm Password"
                    type={showPassword.cpassword ? "text" : "password"}
                    name="cpassword"
                    value={cpassword}
                    onChange={handleDataChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleTogglePasswordVisibility("cpassword")
                            }
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showPassword.cpassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {/* <!-- input container column --> */}

                <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                  <Avatar
                    alt="Avatar Preview"
                    src={avatarPreview}
                    sx={{ width: 56, height: 56 }}
                  />
                  <label className="rounded font-medium bg-gray-400 text-center cursor-pointer text-white w-full py-2 px-2.5 shadow hover:shadow-lg">
                    <input
                      type="file"
                      name="avatar"
                      accept="image/*"
                      onChange={handleDataChange}
                      className="hidden"
                    />
                    Choose File
                  </label>
                </div>
                <button
                  type="submit"
                  className="text-white py-3 w-full bg-primary-blue shadow hover:shadow-lg rounded-sm font-medium"
                >
                  Signup
                </button>
                <Link
                  to="/login"
                  className="hover:bg-gray-50 text-blue-600 text-center py-3 w-full shadow border rounded-sm font-medium"
                >
                  Existing User? Log in
                </Link>
              </div>
            </form>
            {/* <!-- personal info procedure container --> */}
          </div>
          {/* <!-- signup column --> */}
        </div>
        {/* <!-- row --> */}
      </main>
    </>
  );
};

export default Register;
