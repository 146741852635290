import { useSelector } from "react-redux";
import CartItem from "./CartItem";
import PriceSidebar from "./PriceSidebar";
import Stepper from "./Stepper";
import { useNavigate } from "react-router-dom";
import MetaData from "../Layouts/MetaData";

const OrderConfirm = () => {
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state?.cart);
  const { user } = useSelector((state) => state?.user);

  return (
    <>
      <MetaData title="Flipkart: Order Confirmation" />

      <main className="w-full pt-3 dark:bg-primary-black">
        {/* <!-- row --> */}
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
          {/* <!-- cart column --> */}
          <div className="flex-1">
            <Stepper activeStep={2}>
              <div className="w-full bg-white dark:bg-primary-black dark:border dark:border-gray-600 dark:text-white">
                {cartItems?.map((data, i) => (
                  <CartItem
                    product={data}
                    id={data?._id}
                    seller={data?.brand.name ?? "Mahahandloom"}
                    image={data?.brand?.logo?.url}
                    stock={data?.stock}
                    quantity={data?.quantity ?? 1}
                    inCart={false}
                    name={data?.name}
                    price={data?.price}
                    cuttedPrice={data?.cuttedPrice}
                  />
                ))}
              </div>
              <div className="flex justify-between items-center mt-4 bg-white dark:bg-primary-darkBlue dark:text-white px-6 py-3 rounded-b-sm">
                <p className="text-sm">
                  Order confirmation email will be sent to&nbsp;
                  <span className="font-medium">
                    {user?.email ?? "example@gmail.com"}
                  </span>
                </p>
                <button
                  onClick={() => {
                    navigate("/process/payment");
                  }}
                  className="bg-primary-blue px-6 py-2 text-white font-medium rounded-sm shadow hover:shadow-lg uppercase"
                >
                  continue
                </button>
              </div>
            </Stepper>
          </div>

          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>
    </>
  );
};

export default OrderConfirm;
