import React, { useState } from "react";
import MetaData from "../Layouts/MetaData";
import { Link } from "react-router-dom";
import publicCommunication from "../../service/publicCommunication";
import { useSnackbar } from "notistack";
import BackdropLoader from "../Layouts/BackdropLoader";

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { name, email, mobile, message } = contactData;

  const handleDataChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const serverResponse = await publicCommunication?.newContact(contactData);
      if (serverResponse?.data?.success === true) {
        setContactData({ name: "", email: "", mobile: "", message: "" });
        enqueueSnackbar(
          serverResponse?.data?.message || "Message sent successfully",
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(
          serverResponse?.data?.message || "Something went wrong!",
          { variant: "warning" }
        );
      }
    } catch (error) {
      enqueueSnackbar(error?.message ?? "Profile update Failed!", {
        variant: "error",
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <MetaData title="Contact us | Mahahandloom" />
      {loader && <BackdropLoader />}
      <section className="h-auto w-full overflow-hidden dark:bg-primary-black bg-primary-beige flex justify-center items-center">
        <section className="" id="contact">
          <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-15">
            <div className="mb-4">
              <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
                <p className="text-base font-semibold uppercase tracking-wide text-primary-blue ">
                  Contact
                </p>
                <h2 className="font-heading mb-4 font-bold tracking-tight text-primary-pink  text-3xl sm:text-5xl">
                  Get in Touch
                </h2>
                <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600  dark:text-gray-400">
                  For any enquiry do not hesitate to contact or message us.
                </p>
              </div>
            </div>
            <div className="flex items-stretch justify-center">
              <div className="grid md:grid-cols-2">
                <div className="h-full pr-6">
                  <p className="mt-3 mb-12 text-lg text-gray-600 dark:text-gray-400">
                    We're here to help you with all your Mahahandloom queries.
                    Whether you need product information, assistance with
                    orders, or have feedback to share, our team is ready to
                    assist you. Reach out to us via phone, email, or by filling
                    out the contact form. Experience the finest in handloom
                    craftsmanship with Mahahandloom.
                  </p>
                  <ul className="mb-6 md:mb-0">
                    <li className="flex">
                      <div className="flex h-10 w-10 items-center justify-center rounded bg-primary-blue text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-6 w-6"
                        >
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                        </svg>
                      </div>
                      <div className="ml-4 mb-4">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-primary-black dark:text-primary-beige">
                          Our Address
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          HANDLOOMS CORPORATION LTD “MSHC Complex" Umred Road
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                          Nagpur 440024 Maharashtra, India
                        </p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex h-10 w-10 items-center justify-center rounded bg-primary-blue text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-6 w-6"
                        >
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                          <path d="M15 7a2 2 0 0 1 2 2"></path>
                          <path d="M15 3a6 6 0 0 1 6 6"></path>
                        </svg>
                      </div>
                      <div className="ml-4 mb-4">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-primary-black dark:text-primary-beige">
                          Contact
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          Mobile:&nbsp;
                          <Link
                            className="hover:text-blue-600"
                            to={"tel:18002330121"}
                          >
                            +91 18002330121
                          </Link>
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                          Mobile:&nbsp;
                          <Link
                            className="hover:text-blue-600"
                            to={"tel:9579837740"}
                          >
                            +91 9579837740
                          </Link>
                        </p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex h-10 w-10 items-center justify-center rounded bg-primary-blue text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="ml-4 mb-4">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-primary-black dark:text-primary-beige">
                          Email Address
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          <Link
                            className="hover:text-blue-600"
                            target="_blank"
                            to={"mailto:info.indrayani@gmail.com"}
                          >
                            info.indrayani@gmail.com
                          </Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card h-fit max-w-6xl p-5 md:p-12" id="form">
                  <h2 className="mb-4 text-2xl font-bold text-primary-black dark:text-primary-beige">
                    Contact us
                  </h2>
                  <form onSubmit={handleSubmit} id="contactForm">
                    <div className="mb-6">
                      <div className="mx-0 mb-1 sm:mb-4">
                        <div className="mx-0 mb-1 sm:mb-4">
                          <label
                            for="name"
                            className="pb-1 text-xs uppercase tracking-wider"
                          ></label>
                          <input
                            type="text"
                            id="name"
                            placeholder="Your name"
                            className="mb-2 w-full rounded-md border dark:bg-gray-300 border-gray-400 py-2 pl-2 pr-4 shadow-md text-primary-black sm:mb-0"
                            name="name"
                            required
                            value={name}
                            onChange={handleDataChange}
                          />
                        </div>
                        <div className="mx-0 mb-1 sm:mb-4">
                          <label
                            for="email"
                            className="pb-1 text-xs uppercase tracking-wider"
                          ></label>
                          <input
                            type="email"
                            id="email"
                            placeholder="Your email address"
                            className="mb-2 w-full rounded-md border dark:bg-gray-300 border-gray-400 py-2 pl-2 pr-4 shadow-md text-primary-black sm:mb-0"
                            name="email"
                            required
                            value={email}
                            onChange={handleDataChange}
                          />
                        </div>
                        <div className="mx-0 mb-1 sm:mb-4">
                          <label
                            for="mobile"
                            className="pb-1 text-xs uppercase tracking-wider"
                          ></label>
                          <input
                            type="text"
                            id="mobile"
                            placeholder="Your Mobile number"
                            className="mb-2 w-full rounded-md border dark:bg-gray-300 border-gray-400 py-2 pl-2 pr-4 shadow-md text-primary-black sm:mb-0"
                            name="mobile"
                            required
                            maxLength={10}
                            value={mobile}
                            onChange={handleDataChange}
                          />
                        </div>
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <label
                          for="textarea"
                          className="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <textarea
                          required
                          value={message}
                          onChange={handleDataChange}
                          maxLength={200}
                          id="textarea"
                          name="message"
                          cols="30"
                          rows="5"
                          placeholder="Write your message..."
                          className="mb-2 w-full rounded-md border dark:bg-gray-300 border-gray-400 py-2 pl-2 pr-4 shadow-md text-primary-black sm:mb-0"
                        ></textarea>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        disabled={loader}
                        type="submit"
                        className="w-full dark:bg-primary-pink bg-primary-blue text-white px-6 py-3 font-xl rounded-md sm:mb-0"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="py-3">
            <iframe
              title="Mahahandloom map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59544.59974925774!2d79.0223053619008!3d21.13099805772166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c7006bb7249d%3A0x39801dfe5d04686d!2sMaharashtra%20State%20Handloom%20Corporation%20Ltd.!5e0!3m2!1sen!2sin!4v1734500591332!5m2!1sen!2sin"
              width="600"
              height="450"
              className="border-none w-full aspect-video dark:invert"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </section>
    </>
  );
};

export default Contact;
